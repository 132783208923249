//core
import React, {useContext, useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, FormikHelpers, FormikValues } from "formik";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import Button from "../../../../../../UI/Buttons/Button";

//icons
import { NoDataIcon } from "../../../../../../UI/Icons";

//styles
import { useStyles } from "./styles";

//helpers
import { importAll } from "../../../../../../../helpers/importAll";

//selectors
import {
  selectEditPaymentData,
  selectGetAvailablePaymentsData
} from "../../../../../../../store/admin/reports/details_finance_report/selectors";

//hooks
import {
  useDetailsFinReport
} from "../../../../../../../store/admin/reports/details_finance_report/useFinReport";
import useQuery from "../../../../../../../customHooks/useQuery";

//validation
import {
  validationRequiredAutocompleteField
} from "../../../../../../validation/requiredAutocompleteStringId.validation";

//context
import { DetailsContext } from "../../../../../../admin/Reports/Finance_report/components/Details/DetailsContext";

//components
import SelectIconsAutocomplete from "../../../../../../UI/FormFields/SelectIconsAutocomplete";
import MuiTextField from "../../../../../../UI/FormFields/MuiTextField";
import HelperField from "./HelperField";

interface IPaymentMethodFormProps {
  affiliateId: number;
  onCancel: () => void;
  onGenerate?: () => void;
}

const PaymentMethodForm: React.FC<IPaymentMethodFormProps> = ({
  onCancel,
  affiliateId,
  onGenerate,
}): JSX.Element => {
  const styles = useStyles();
  const { t,i18n } = useTranslation();

  const context = useContext(DetailsContext);

  const { getAvailablePayments, editPayment, clearEditPayment, getReportRow, clearAvailablePayments } = useDetailsFinReport();
  const data = useSelector(selectGetAvailablePaymentsData)
  const edit = useSelector(selectEditPaymentData)

  const formRef: any = useRef();
  const { reportId } = useParams();
  let query = useQuery();

  const [initialValues, setInitialValues] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [errorsMessages, setErrorsMessages] = useState({
    required: t("common.validation.required_field"),
    requiredRegex: t("common.validation.required_regex"),
  });

  useEffect(() => {
    if (!!edit) {
      const payload = {
        affiliateId: affiliateId,
        periodId: Number(reportId),
        ...(!!query.get('brand') ? { brand: query.get('brand') } : {}),
        ...(!!query.get('currency') ? { currency: query.get('currency') } : {}),
      }

      getReportRow(payload)
      onCancel();
    }
  }, [edit]);

  useEffect(() => {
    return () => {
      clearEditPayment();
      clearAvailablePayments();
    };
  }, []);

  useEffect(() => {
    setErrorsMessages({
      required: t("common.validation.required_field"),
      requiredRegex: t("common.validation.required_regex"),
    })
  }, [i18n.language, t]);

  //set initial form value
  useEffect(() => {
    if (!!data && data.length > 0) {
      const currentPaymentMethod = data.filter(
        (item:any) => item.isDefault
      )[0];
      const initState = {
        id: currentPaymentMethod.paymentMethodName,
        payment_method: {
          ...currentPaymentMethod,
        },
        fields: Object.keys(currentPaymentMethod.paymentMethodValue).map((item: any) => {
          return {
            name: item,
            value: currentPaymentMethod.paymentMethodValue[item],
          }
        }),
        is_default: currentPaymentMethod.isDefault,
      };

      setInitialValues(initState);
    }
  }, [data]);

  useEffect(() => {
    getAvailablePayments({
      affiliateId: affiliateId,
      periodId: Number(reportId),
    })
  }, []);

  const submitForm = (values: FormikValues, actions: FormikHelpers<any>): void => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      const payload = {
        affiliateId: affiliateId,
        periodId: Number(reportId),
        payload: {
          ...values.payment_method,
          affiliateId: affiliateId,
          periodId: Number(reportId),
        }
      };

      editPayment(payload);

      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000);

      setTimeout(() => {
        context.onGenerateCurrentState();
        if (typeof onGenerate === 'function') {
          onGenerate();
        }
      }, 2000);

      actions.setSubmitting(false);
    }
  };

  //@ts-ignore
  const paymentImages = importAll(require.context('../../../../../../../assets/images/payments', false, /\.(png|jpe?g|svg)$/));

  const validatePaymentMethodField = (value: any) => validationRequiredAutocompleteField(value, errorsMessages);

  return (
    <>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {t("admin.reports.detailed_fin_report.wallet.edit_wallet.title")}
      </Typography>
      { !initialValues && (!data) ? <div className={styles.loader_wrapper}><CircularProgress className={styles.loader} /></div>
      : !!initialValues && (!!data && data.length > 0) ? (
        <Formik
          validateOnChange
          validateOnBlur
          validateOnMount
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={ (values, actions) => submitForm(values, actions) }
        >
          { props => {
            const {values, setFieldValue, setValues, setFieldError, setTouched, setFieldTouched, handleChange, isValid, dirty, errors} = props;

            const disableSubmit = isValid ? !dirty : true;

            return (
              <Form>
                <Box className={styles.field}>
                  <SelectIconsAutocomplete
                    keyValue="paymentMethodName"
                    showValue='showValue'
                    keyId="id"
                    id="payment_method"
                    name="payment_method"
                    label={t(`common.components.payment.form.payment_method`)}
                    value={values.payment_method}
                    multiple={false}
                    showSelectAll={false}
                    blurOnSelect={true}
                    staticOptions={data}
                    // prefix={'common.components.payment.payment_method_options.'}
                    images={paymentImages}
                    //disabled={data.countries.disabled}
                    validate={validatePaymentMethodField}
                  />
                </Box>

                {!!values.fields.length && (<>
                  {
                    values.fields.map((item: any, i: number) => {
                      return (
                        <Box className={styles.field} key={`${item.name}-${i}`}>
                          <MuiTextField
                            size="small"
                            id={item.name}
                            type="text"
                            name={item.name}
                            autoComplete={item.name}
                            label={t(`common.components.payment.form.${item.name}`)}
                            value={values.fields[i].value}
                            onChange={(e: any) =>  e.target.value}
                            disabled={true}
                          />
                        </Box>
                      )
                    })
                  }

                  {/*<Box className={styles.field}>*/}
                  {/*  <MuiSwitch*/}
                  {/*    id="is_default"*/}
                  {/*    name="is_default"*/}
                  {/*    label={t("common.components.payment.form.is_default")}*/}
                  {/*    checked={values.is_default}*/}
                  {/*    onChange={handleChange}*/}
                  {/*    disabled={data.isDefault}*/}
                  {/*    size="medium"*/}
                  {/*  />*/}
                  {/*</Box>*/}
                </>)}

                <HelperField
                  name="helper-field"
                  value={values}
                />

                <Box className={`${styles.actions}`}>
                  <Button
                    gradient
                    fullWidth
                    disableElevation
                    type="submit"
                    variant="contained"
                  >{t("common.buttons.save")}</Button>

                  <Button
                    fullWidth
                    disableElevation
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={onCancel}
                  >{t("common.buttons.cancel")}</Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <Box className={styles.empty}>
          <NoDataIcon className={styles.img} />
          <Typography className={styles.empty_text} variant="h6" component="div">
            {t("admin.reports.detailed_fin_report.wallet.edit_wallet.empty_title")}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default React.memo(PaymentMethodForm);
