//core
import React, {useState, useContext, useEffect, useCallback} from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  Typography,
  Chip,
  FormControlLabel,
  IconButton,
  Radio,
  Tooltip,
} from "@mui/material";

//icons
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//styles
import { useStyles } from "./styles";

//hooks
import { useStorage } from "../../../../../../../../store/common/storage/useStorage";
import { useUI } from "../../../../../../../../store/common/ui/useUI";

//constants
import { reportStorageProps } from "../../../../constants";

//context
import { ThemeContext } from "../../../../../../../UI/MuiTheme/context";

//components
import ItemMenu from "./components/ItemMenu";


interface ISavedReportItemProps {
  data: any;
  isDefault: boolean;
  setToggleDrawer: (data: boolean) => void;
  savedReportsFavoritesIds: null | number[];
  setSavedReportsFavoritesIds: React.Dispatch<React.SetStateAction<null | number[]>>;
  savedReportsDefaultId: null | number[];
  setSavedReportsDefaultId: React.Dispatch<React.SetStateAction<null | number[]>>;
  savedReportsDefaultItem: any | null;
  getSavedReports: () => void;
  savedReportsFavoritesItem: any | null;
  getSavedReportsDefault: () => void;
}

const SavedReportItem: React.FC<ISavedReportItemProps> = ({
  data,
  isDefault,
  setToggleDrawer,
  savedReportsFavoritesIds,
  setSavedReportsFavoritesIds,
  savedReportsDefaultId,
  setSavedReportsDefaultId,
  savedReportsDefaultItem,
  getSavedReports,
  savedReportsFavoritesItem,
  getSavedReportsDefault,
}): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const { darkMode } = useContext(ThemeContext);

  const { createItem, updateItem } = useStorage();
  const { setGeneralSnackbar } = useUI();

  const [favorite, setFavorite] = useState(savedReportsFavoritesIds?.includes(data.id));

  useEffect(() => {
    setFavorite(savedReportsFavoritesIds?.includes(data.id));
  }, [data, savedReportsFavoritesIds]);

  const handleAddToFavorites = () => {
    if (!!savedReportsFavoritesIds && !!savedReportsFavoritesIds.length) {
      if (savedReportsFavoritesIds.includes(data.id)) {
        const arr = [...savedReportsFavoritesIds.filter(el => el !== data.id)];
        setSavedReportsFavoritesIds(arr);

        updateItem({
          type: reportStorageProps.type,
          target: reportStorageProps.savedReportFavoritesTarget,
          id: savedReportsFavoritesItem.id,
          payload: {
            name: "Favorites reports",
            data: JSON.stringify(arr),
          }
        })

      } else {
        const arr = [...savedReportsFavoritesIds, data.id];

        if (arr.length <= reportStorageProps.maxFavoriteReports) {
          setSavedReportsFavoritesIds(arr);

          updateItem({
            type: reportStorageProps.type,
            target: reportStorageProps.savedReportFavoritesTarget,
            id: savedReportsFavoritesItem.id,
            payload: {
              name: "Favorites reports",
              data: JSON.stringify(arr),
            }
          });
        } else {
          setGeneralSnackbar({
            open: true,
            type: 'warning',
            message: t("common.messages.favorite_reports_max").replace('{max}', `${reportStorageProps.maxFavoriteReports}`),
          })
        }
      }
    } else {

      const payload = {
        type: reportStorageProps.type,
        target: reportStorageProps.savedReportFavoritesTarget,
        payload: {
          name: "Favorites reports",
          data: JSON.stringify([data.id]),
        }
      };

      if (!!savedReportsFavoritesItem?.id) {
        updateItem({
          type: reportStorageProps.type,
          target: reportStorageProps.savedReportFavoritesTarget,
          id: savedReportsFavoritesItem.id,
          payload: {
            name: "Favorites reports",
            data: JSON.stringify([data.id]),
          }
        });
      } else {
        createItem(payload);
      }

      setSavedReportsFavoritesIds([data.id]);
    }
  };

  return (
    <Box className={styles.block}>
      <FormControlLabel
        className={styles.radio}
        value={`${data.id}`}
        control={
          <Radio />
        }
        label=''
      />

      <Card elevation={5} className={`${styles.wrapper} ${darkMode ? 'dark' : 'light'}`}>
        <Box className={styles.header}>
          <Box className={styles.first}>
            <Tooltip title={favorite ? t('common.components.saved_report.remove_from_favorites') : t('common.components.saved_report.add_to_favorites')}>
              <IconButton
                aria-label="collapse"
                className={styles.iconBtn}
                onClick={handleAddToFavorites}
              >
                {favorite
                  ? <StarIcon fontSize="small" />
                  : <StarOutlineIcon fontSize="small" />
                }
              </IconButton>
            </Tooltip>

            <Typography className={styles.name} variant="body1">
              {data.name}
            </Typography>
          </Box>

          <Box className={styles.second}>
            {isDefault && (
              <Chip
                label={t("common.components.saved_report.default")}
                variant="outlined"
                size="small"
                color="primary"
              />
            )}
            <ItemMenu
              data={data}
              isDefault={isDefault}
              setToggleDrawer={setToggleDrawer}
              savedReportsDefaultId={savedReportsDefaultId}
              setSavedReportsDefaultId={setSavedReportsDefaultId}
              savedReportsDefaultItem={savedReportsDefaultItem}
              getSavedReports={getSavedReports}
              getSavedReportsDefault={getSavedReportsDefault}
              savedReportsFavoritesIds={savedReportsFavoritesIds}
              setSavedReportsFavoritesIds={setSavedReportsFavoritesIds}
              savedReportsFavoritesItem={savedReportsFavoritesItem}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default SavedReportItem;
