//core
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  IconButton,
  useMediaQuery,
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//icons
import { TableEditIcon } from "../../../UI/Icons";

//hooks
import { setUser } from "../../../../store/common/user";

//service
import { storageService } from "../../../../services/common/storage.service";

//helpers
import { getCookie } from "../../../../helpers/utils";

//constants
import { cookieSupervision } from "../../../../constants";

//components
import MuiDrawerWrapper from "../../../UI/MuiDrawerWrapper";
import Settings from "./components/Settings";
import { TableColumnsItem, TableColumnsOrderedItem } from "./types";
import { ListPayloadType } from "../../../../store/common/storage";

interface ITableSettingsProps {
  columns: TableColumnsItem[];
  defaultColumns: TableColumnsItem[];
  setColumns: React.Dispatch<React.SetStateAction<TableColumnsItem[]>>;
  setGenerateReport: React.Dispatch<React.SetStateAction<boolean>>;
  prefix: string;
  storageType: string;
  storageTarget: string;
  columnCurrency?: any;
}

const TableSettings: React.FC<ITableSettingsProps> = ({
  columns,
  setColumns,
  defaultColumns,
  prefix,
  storageType,
  storageTarget,
  setGenerateReport,
  columnCurrency = null,
}): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mobile = useMediaQuery('(max-width:767px)');
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const [openDrawer, setOpenDrawer] = useState(false);

  const [tableSettingsColumns, setTableSettingsColumns] = useState<TableColumnsItem[] | null>(null);
  const [checkColumns, setCheckColumns] = useState<TableColumnsItem[] | null>(null);
  const [savedSettings, setSavedSettings] = useState<any | null>(null);

  useEffect(() => {
    setTableSettingsColumns((prevState: any) => {
      if (!!prevState) {
        const columnsIds = defaultColumns.map((item) => item.id);

        let table: { [key: string]: any } = {};
        const allColumns = [
          ...columns.map((item, i) => {
            return {
              ...item,
              order: i,
            }
          }),
          ...defaultColumns.map((item: TableColumnsItem, i: number) => {
            return {
              ...item,
              order: columns.length + i,
            }
          }),
        ].filter(({id}) =>(!table[id] && (table[id] = 1)));

        const actualColumnsState = allColumns
          .reduce((acc: TableColumnsOrderedItem[], item: TableColumnsOrderedItem): TableColumnsOrderedItem[] => {
            if (columnsIds.includes(item.id)) {
              acc.push(item);
            }
            return acc;
          }, [])
          .sort((a: TableColumnsOrderedItem, b: TableColumnsOrderedItem) => a.order > b.order ? 1 : -1)
          .map((item: TableColumnsOrderedItem) => {
            return {
              id: item.id,
              value: item.value,
              checked: item.checked,
            }
          });
        return actualColumnsState;
      } else {
        return prevState;
      }
    });
  }, [columns]);

  useEffect(() => {
    getTableSettings(getPayloadFilters()).then((data) => {
      if (data.list.length === 0) {
        setTableSettingsColumns(defaultColumns);
        setCheckColumns(defaultColumns);
        setSavedSettings(null);
      } else {
        getStorageItemById(data.list[0].id).then((res) => {
          const savedColumns = JSON.parse(res.data);
          const columnsIds = columns.map((item) => item.id);

          let table: { [key: string]: any } = {};
          const allColumns = [
            ...savedColumns.map((item: TableColumnsItem, i: number) => {
              return {
                ...item,
                order: i,
              }
            }),
            ...columns.map((item, i) => {
              return {
                ...item,
                order: savedColumns.length + i,
              }
            })
          ].filter(({id}) =>(!table[id] && (table[id] = 1)));

          setSavedSettings({
            id: res.id,
            data: savedColumns,
          });

          const actualColumnsState = allColumns
            .reduce((acc, item) => {
              if (columnsIds.includes(item.id)) {
                acc.push(item);
              }
              return acc;
            }, [])
            .sort((a: TableColumnsOrderedItem, b: TableColumnsOrderedItem) => a.order > b.order ? 1 : -1)
            .map((item: TableColumnsOrderedItem) => {
              return {
                id: item.id,
                value: item.value,
                checked: item.checked,
              }
            });

          setTableSettingsColumns(actualColumnsState);
          setCheckColumns(actualColumnsState);
          setColumns(actualColumnsState);
        })
      }
    })
  }, []);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  }

  const getTableSettings = async (payload: ListPayloadType) => {
    try {
      const response = await storageService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const getPayloadFilters = (): ListPayloadType => {
    return {
      payload: {
        page: 1,
        per_page: 100,
        order: {
          updated: "desc",
        },
      },
      type: storageType,
      target: storageTarget,
    }
  };

  const getStorageItemById = async (id: number) => {
    try {
      const response = await storageService.getById({
        type: storageType,
        target: storageTarget,
        id: id,
      });

      return response;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <IconButton
        className={styles.iconButton}
        size={mobile ? 'small' : 'medium'}
        onClick={handleOpenDrawer}
      >
        <TableEditIcon />
      </IconButton>

      <MuiDrawerWrapper
        title={t("common.components.table_settings.title")}
        open={openDrawer}
        toggleDrawerState={setOpenDrawer}
      >
        {!!tableSettingsColumns && (
          <Settings
            columns={tableSettingsColumns}
            setColumns={setColumns}
            defaultColumns={defaultColumns}
            prefix={prefix}
            setToggleDrawer={setOpenDrawer}
            storageType={storageType}
            storageTarget={storageTarget}
            savedSettings={savedSettings}
            setGenerateReport={setGenerateReport}
            columnCurrency={columnCurrency}
          />
        )}
      </MuiDrawerWrapper>
    </>
  );
};

export default TableSettings;
