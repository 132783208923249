import React from "react";
import { useTranslation } from "react-i18next";

interface ISettingUpdateProps {
  data: any;
}

const SettingUpdate: React.FC<ISettingUpdateProps> = ({
  data,
}): JSX.Element => {
  const { t } = useTranslation();

  const setting = data.settingType !== 'ftd_filter' && `${t(`admin.partners.trackers.additional_settings.logs.setting_type.options.${data.settingType}`)} ${t("admin.partners.trackers.additional_settings.logs.update_setting_type")
    .replace('{from}', `${data.changes.value.from}`)
    .replace('{to}', `${data.changes.value.to}`)}`;

  const ftd_setting = data.settingType === 'ftd_filter' && !data.changes.hasOwnProperty('type') && `${t("admin.partners.trackers.additional_settings.logs.update_value_ftd")
    .replace('{type}', `${t("common.forms.fields.filterFtd.label")}, ${data.changes.hasOwnProperty('percentage') ? '%' : 'count'}`)
    .replace('{from}', `${data.changes.hasOwnProperty('percentage') ? data.changes.percentage.from : data.changes.multiplier.from}`)
    .replace('{to}', `${data.changes.hasOwnProperty('percentage') ? data.changes.percentage.to : data.changes.multiplier.to}`)
  }`;

  const ftd_setting_change_types = data.settingType === 'ftd_filter' && data.changes.hasOwnProperty('type') && data.changes.type.hasOwnProperty('from') && `${t("admin.partners.trackers.additional_settings.logs.update_value_type_ftd")
    .replace('{type_from}', `${t("common.forms.fields.filterFtd.label")}, ${data.changes.type.from === 'percentage' ? '%' : 'count'}`)
    .replace('{type_to}', `${t("common.forms.fields.filterFtd.label")}, ${data.changes.type.to === 'percentage' ? '%' : 'count'}`)
    .replace('{value_from}', `${!!data.changes.percentage.from ? data.changes.percentage.from : data.changes.multiplier.from}`)
    .replace('{value_to}', `${!!data.changes.percentage.to ? data.changes.percentage.to : data.changes.multiplier.to}`)
  }`;

  const message = `
    ${!!setting ? setting : ''}${!!ftd_setting ? ftd_setting : ''}${!!ftd_setting_change_types ? ftd_setting_change_types : ''}
  `;

  return (
    <>
      {message}
    </>
  );
};

export default SettingUpdate;
