import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  iconBtn: {
    padding: 4,
  },

  icon: {
    color: theme.palette.action.active,
    marginRight: 16,
  },

  divider: {
    marginTop: '0!important',
    marginBottom: '0!important',
  },

  footer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: 16,
    paddingTop: 32,
    '@media (max-width: 460px)': {
      flexDirection: "column"
    }
  },
  button: {
    width: "100%",
  },

  title: {
    wordBreak: 'break-all',
    marginBottom: 8,
  },


  /*actions: {
    //borderTop: `1px solid ${alpha(theme.palette.secondary.main, 0.12)}`,
    /!*'@media (max-width: 767px)': {
      width: '100%',
      padding: '16px 16px',
    }*!/
  },*/
}));
