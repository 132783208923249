//core
import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import {
  Box,
  Typography
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//helpers
import { isObjectEmpty } from "../../../../../../../../helpers/isObjectEmpty";

//hooks
import { useStorage } from "../../../../../../../../store/common/storage/useStorage";

//validation
import { validationTextField as validationTextFieldMax } from "../../../../../../../validation/textFieldMax.validation";
import MuiTextField from "../../../../../../../UI/FormFields/MuiTextField";
import Button from "../../../../../../../UI/Buttons/Button";
import {ISummaryReportFilterDataType} from "../../../../types";
import {TableColumnsItem} from "../../../../../../../common/FiltersComponents/TableSettings/types";


interface ISaveReportSettingsProps {
  type: string;
  target: string;
  filter: ISummaryReportFilterDataType;
  columns: TableColumnsItem[];
  savedReportsData: null | any;
  setToggleModal: (state: boolean) => void;
  getSavedReports: () => void;
}

const SaveReportSettings: React.FC<ISaveReportSettingsProps> = ({
  type,
  target,
  filter,
  columns,
  savedReportsData,
  setToggleModal,
  getSavedReports,
}): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const formRef: any = useRef();

  const { createItem } = useStorage();

  const [initialValues, setInitialValues] = useState<any>({
    name: '',
  });

  // validation errors translation
  const [errorsMessages, setErrorsMessages] = useState({
    required: t("common.validation.required_field"),
    maxLength: t("common.validation.max_length"),
  });

  useEffect(() => {
    setErrorsMessages({
      required: t("common.validation.required_field"),
      maxLength: t("common.validation.max_length"),
    });

    if (formRef.current) {
      if (!isObjectEmpty(formRef.current.errors)) {
        setTimeout(() => {
          formRef.current.validateForm();
        }, 50)
      }
    }
  }, [i18n.language, t]);

  const submitForm = (values: any, actions: FormikHelpers<any>) => {
    const payload = {
      type,
      target,
      payload: {
        name: values.name,
        data: JSON.stringify({
          filter: {
            ...filter,
            sent_from: "",
            sent_to: "",
            period: 'month',
          },
          columns,
        }),
      }
    };

    createItem(payload);

    actions.setSubmitting(false);
    if (formRef.current) {
      formRef.current.resetForm();
      setTimeout(() => {
        getSavedReports();
      }, 200);
      setToggleModal(false);
    }
  };

  const validateName = (value: any) => validationTextFieldMax(value, 255, errorsMessages);

  return (
    <Box className={styles.block}>
      <Typography className={styles.title} variant="h6">
        {t("common.components.saved_reports.enter_report_name")}
      </Typography>

      <Formik
        validateOnChange
        validateOnBlur
        validateOnMount
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={ (values, actions) => submitForm(values, actions) }
      >
        {props => {
          const { values, handleSubmit, handleChange, isValid, dirty } = props;
          const disableSubmit = isValid ? !dirty : true;

          return (
            <form onSubmit={handleSubmit}>
              <Box className={styles.field}>
                <MuiTextField
                  size="small"
                  id="name"
                  type="text"
                  name="name"
                  autoComplete="name"
                  label={t("common.forms.fields.name")}
                  value={values.name}
                  validate={validateName}
                  onChange={handleChange}
                  autoFocus={true}
                />
              </Box>

              <Box className={styles.footer}>
                <Button
                  className={styles.button}
                  gradient
                  fullWidth
                  disableElevation
                  type="submit"
                  variant="contained"
                  disabled={disableSubmit}
                >{t("common.buttons.save")}</Button>
                <Button
                  className={styles.button}
                  fullWidth
                  disableElevation
                  type="button"
                  variant="outlined"
                  color="secondary"
                  //onClick={() => handleBack(values)}
                >{t("common.buttons.cancel")}</Button>
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  );
};

export default SaveReportSettings;
