//core
import React, {Dispatch, SetStateAction, useCallback, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import arePropsEqual from "react-fast-compare";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  Box,
  Typography,
} from "@mui/material";
import Button from "../../../../../UI/Buttons/Button";

//styles
import {
  useStyles,
  ColumnsList,
} from "./styles";

//types
import {CreatePayloadType, DeletePayloadType, UpdatePayloadType} from "../../../../../../store/common/storage";
import { TableColumnsItem } from "../../types";

//service
import { storageService } from "../../../../../../services/common/storage.service";

//hooks
import { setUser } from "../../../../../../store/common/user";

//helpers
import { getCookie } from "../../../../../../helpers/utils";

//constants
import { cookieSupervision } from "../../../../../../constants";

//components
import TableColumnItem from "./TableColumnItem";
import {reportStorageProps} from "../../../../../admin/Reports/SummaryReportV2/constants";
import {useStorage} from "../../../../../../store/common/storage/useStorage";

interface ISettingsProps {
  columns: TableColumnsItem[];
  defaultColumns: TableColumnsItem[];
  setColumns: React.Dispatch<React.SetStateAction<TableColumnsItem[]>>;
  setToggleDrawer: Dispatch<SetStateAction<boolean>>;
  prefix: string;
  storageType: string;
  storageTarget: string;
  savedSettings: any;
  setGenerateReport: React.Dispatch<React.SetStateAction<boolean>>;
  columnCurrency?: any;
}

const Settings: React.FC<ISettingsProps> = ({
  columns,
  setColumns,
  defaultColumns,
  prefix,
  setToggleDrawer,
  storageType,
  storageTarget,
  savedSettings,
  setGenerateReport,
  columnCurrency = null,
}): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const { createItem, updateItem, deleteItem } = useStorage();

  const [columnsList, setColumnsList] = useState(columns);
  const [hideAll, setHideAll] = useState(false);
  const [disableToggleBtn, setDisableToggleBtn] = useState(false);

  useEffect(() => {
    setDisableToggleBtn(false);
  }, [columnsList]);

  useEffect(() => {
    const totalColumns = columns.length;
    const totalVisibleColumns = columnsList.filter((item) => item.checked).length;
    const totalHiddenColumns = columnsList.filter((item) => !item.checked).length;

    if (totalVisibleColumns === totalColumns) {
      setHideAll(false);
    }

    if (totalHiddenColumns === totalColumns) {
      setHideAll(true);
    }
  }, [columns, columnsList]);

  useEffect(() => {
    setColumnsList(columns);
  }, [columns]);

  const handleApplyFilter = useCallback(() => {
    setColumns(columnsList);
    setToggleDrawer(false);
    if (!isSupervision) {
      if (!savedSettings) {
        //create
        createTableSettings({
          type: storageType,
          target: storageTarget,
          payload: {
            name: `${storageType}-table-columns`,
            data: JSON.stringify(columnsList),
          }
        })
      } else {
        //update
        updateTableSettings({
          type: storageType,
          target: storageTarget,
          id: savedSettings.id,
          payload: {
            name: `${storageType}-table-columns`,
            data: JSON.stringify(columnsList),
          }
        });
      }
    }

    setTimeout(() => {
      setGenerateReport((prevState) => {
        return !prevState
      });
    }, 200)
  }, [columnsList]);

  const createTableSettings = async (payload: CreatePayloadType) => {
    try {
      const response = await storageService.create(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const updateTableSettings = async (payload: UpdatePayloadType) => {
    try {
      const response = await storageService.update(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const removeTableSettings = async (payload: DeletePayloadType) => {
    try {
      const response = await storageService.remove(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const handleToDefault = () => {
    setColumnsList(defaultColumns);
    setHideAll(false);

    /*removeTableSettings({
      type: reportStorageProps.type,
      target: reportStorageProps.tableSettingsTarget,
      id: savedSettings.id,
    })*/
  };

  const handleToggleHideAll = (value: boolean) => {
    setDisableToggleBtn(true);
    if (value) {
      setColumnsList((prevState: any) => {
        return prevState.map((item: any) => {
          return {
            ...item,
            checked: true,
          }
        });
      });
    } else {
      setColumnsList((prevState: any) => {
        return prevState.map((item: any) => {
          return {
            ...item,
            checked: false,
          }
        });
      });
    }
    setHideAll(!value);
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(columnsList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setColumnsList(items);
  };

  return (
    <>
      <Box className={styles.wrapper}>
        <Box className={styles.header}>
          <Box className={styles.headerFirst}>
            <Typography variant="h6">
              {t("common.components.widgets.settings.shown")}
            </Typography>
          </Box>

          <Box className={styles.headerSecond}>
            <Button
              size="small"
              color="primary"
              variant="text"
              onClick={handleToDefault}
              disabled={arePropsEqual(columnsList, defaultColumns)}
            >
              {t("common.buttons.revert_to_default")}
            </Button>
            <Button
              size="small"
              color="primary"
              variant="text"
              sx={{ minWidth: '106px' }}
              onClick={() => handleToggleHideAll(hideAll)}
              disabled={disableToggleBtn}
            >
              {hideAll ? t("common.buttons.show_all") : t("common.buttons.hide_all")}
            </Button>
          </Box>
        </Box>

        <Scrollbars
          className={styles.customScroll}
          style={{ overflow: "hidden" }}
          hideTracksWhenNotNeeded={true}
          renderView={props => <div {...props} className="view"/>}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
          renderTrackVertical={props => <div {...props} className="track-vertical"/>}
        >
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="metrics">
              {(provided) => (
                <ColumnsList className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                  {columnsList.map((item: any, index: number) => {
                    return (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <TableColumnItem
                            data={item}
                            provided={provided}
                            setUpdateValue={setColumnsList}
                            prefix={prefix}
                            columnCurrency={columnCurrency}
                          />
                        )}
                      </Draggable>
                    )
                  })}
                </ColumnsList>
              )}
            </Droppable>
          </DragDropContext>
        </Scrollbars>
      </Box>

      <Box className={styles.actions}>
        <Box className={styles.actionsWrapper}>
          <Button
            gradient
            className={styles.button}
            fullWidth
            disableElevation
            type="submit"
            variant="contained"
            size="large"
            onClick={handleApplyFilter}
            disabled={arePropsEqual(columns, columnsList)}
          >
            {t("common.buttons.apply")}
          </Button>
          <Button
            className={styles.button}
            color="secondary"
            disableElevation
            type="button"
            variant="outlined"
            size="large"
            onClick={() => setToggleDrawer(false)}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Settings;
