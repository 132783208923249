import React, {useCallback, useContext, useState} from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  Typography,
  Box,
  IconButton,
} from "@mui/material";

//icons
import { CopyAll, Edit } from "@mui/icons-material";

//styles
import { useStyles } from "./styles";

//hooks
import { useUI } from "../../../../../store/common/ui/useUI";

//components
import MuiModal from "../../../../UI/MuiModal";
import EditWallet from "./components/EditWallet";
import TooltipText from "../TooltipText";
import PermissionsWrapper from "../../../PermissionsWrapper";

interface IPaymentWalletProps {
  data: any;
  onGenerate?: () => void;
}

const PaymentWallet: React.FC<IPaymentWalletProps> = ({
  data,
  onGenerate = () => {},
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { setGeneralSnackbar } = useUI();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const value = !!data && data.hasOwnProperty('paymentMethodValue')
    && (
      data.paymentMethodValue.hasOwnProperty('wallet_number')
        ? data.paymentMethodValue.wallet_number
        : (data.paymentMethodValue.hasOwnProperty('iban')
          ? data.paymentMethodValue.iban
          : (data.paymentMethodValue.hasOwnProperty('other')
            ? data.paymentMethodValue.other
            : (data.paymentMethodValue.hasOwnProperty('email')
              ? data.paymentMethodValue.email
              : '')))
    );

  const handleCopyWalletNumber = () => {
    setGeneralSnackbar({
      open: true,
      type: 'success',
      message: 'success',
      messageKey: 'common.messages.copied',
    });
  }

  const handleToggleEditModal = useCallback((state: boolean) => {
    setShowEditModal(state);
  }, [setShowEditModal]);

  return (
    <>
      <Box className={styles.block}>
        <Typography variant="body2">
          <TooltipText value={value} width={160} maxWidth={160}/>
        </Typography>

        {!!data && data.hasOwnProperty('paymentMethodValue') && <>
          <Box className={styles.actions}>
            <PermissionsWrapper permissions={["api3.admin.financereportdetailedcontroller.affiliate_payment_data_edit"]}>
              <IconButton
                color="default"
                size="small"
                className={styles.iconButton}
                onClick={() => handleToggleEditModal(true)}
              >
                <Edit fontSize="small" color='action'/>
              </IconButton>
            </PermissionsWrapper>

            {!!value && (
              <CopyToClipboard
                onCopy={handleCopyWalletNumber}
                text={value}
              >
                <IconButton
                  color="default"
                  size="small"
                  className={styles.iconButton}
                >
                  <CopyAll fontSize="small" color='action'/>
                </IconButton>
              </CopyToClipboard>
            )}
          </Box>
        </>}
      </Box>

      <MuiModal
        width={472}
        open={showEditModal}
        closeOnOutside={false}
        setToggleModal={setShowEditModal}
      >
        <EditWallet
          affiliateId={data.affiliateId}
          onCancel={() => handleToggleEditModal(false)}
          onGenerate={onGenerate}
        />
      </MuiModal>
    </>
  );
};

export default PaymentWallet;
