import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 4,
    '@media (max-width: 1500px)': {},
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 4,
  },
  iconButton: {
    padding: 4,
  },
}));
