//core
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box, Button,
  Divider,
  IconButton,
  Menu,
  MenuItem, Typography,
} from "@mui/material";

//icons
import {
  CopyAll,
  MoreVert,
  Favorite,
  Delete,
} from "@mui/icons-material";

//styles
import { useStyles } from "./styles";

//hooks
import { useStorage } from "../../../../../../../../../../store/common/storage/useStorage";
import { useUI } from "../../../../../../../../../../store/common/ui/useUI";

//constants
import { reportStorageProps } from "../../../../../../constants";

//components
import MuiModal from "../../../../../../../../../UI/MuiModal";


interface IItemMenuProps {
  data: any;
  isDefault: boolean;
  setToggleDrawer: (data: boolean) => void;
  savedReportsDefaultId: null | number[];
  setSavedReportsDefaultId: React.Dispatch<React.SetStateAction<null | number[]>>;
  savedReportsDefaultItem: any | null;
  getSavedReports: () => void;
  getSavedReportsDefault: () => void;
  savedReportsFavoritesIds: null | number[];
  setSavedReportsFavoritesIds: React.Dispatch<React.SetStateAction<null | number[]>>;
  savedReportsFavoritesItem: any | null;
}

const ItemMenu: React.FC<IItemMenuProps> = ({
  data,
  isDefault,
  setToggleDrawer,
  savedReportsDefaultId,
  setSavedReportsDefaultId,
  savedReportsDefaultItem,
  getSavedReports,
  getSavedReportsDefault,
  savedReportsFavoritesIds,
  setSavedReportsFavoritesIds,
  savedReportsFavoritesItem,
}): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { createItem, updateItem, deleteItem } = useStorage();
  const { setGeneralSnackbar } = useUI();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDefaultDeleteModal, setShowDefaultDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  },[setAnchorEl]);

  const handleToggleShowDeleteModal = useCallback((state: boolean) => {
    setShowDeleteModal(state);
  }, [setShowDeleteModal]);

  const handleToggleShowDefaultDeleteModal = useCallback((state: boolean) => {
    setShowDefaultDeleteModal(state);
  }, [setShowDefaultDeleteModal]);

  const handleSetIsDefault = () => {
    handleClose();
    setSavedReportsDefaultId([data.id]);
    if (!!savedReportsDefaultId && !!savedReportsDefaultId.length) {
      if (!!savedReportsDefaultItem) {
        updateItem({
          type: reportStorageProps.type,
          target: reportStorageProps.savedReportDefaultTarget,
          id: savedReportsDefaultItem.id,
          payload: {
            name: 'Default report',
            data: JSON.stringify([data.id]),
          }
        });

        setGeneralSnackbar({
          open: true,
          type: 'success',
          message: t("common.messages.set_default_report").replace('{name}', `${data.name}`),
        });
      }
    } else {
      createItem({
        type: reportStorageProps.type,
        target: reportStorageProps.savedReportDefaultTarget,
        payload: {
          name: 'Default report',
          data: JSON.stringify([data.id]),
        }
      });
      setGeneralSnackbar({
        open: true,
        type: 'success',
        message: t("common.messages.set_default_report").replace('{name}', `${data.name}`),
      });
      setToggleDrawer(false);
      setTimeout(() => {
        getSavedReportsDefault();
      }, 200)
    }
  };

  const handleDelete = () => {
    handleToggleShowDeleteModal(false);

    deleteItem({
      type: reportStorageProps.type,
      target: reportStorageProps.savedReportDefaultTarget,
      id: 1001,
    });

    deleteItem({
      type: reportStorageProps.type,
      target: reportStorageProps.savedReportTarget,
      id: data.id,
    });

    if (!!savedReportsFavoritesIds && !!savedReportsFavoritesIds.length) {
      if (savedReportsFavoritesIds.includes(data.id)) {
        const arr = [...savedReportsFavoritesIds.filter(el => el !== data.id)];
        setSavedReportsFavoritesIds(arr);

        updateItem({
          type: reportStorageProps.type,
          target: reportStorageProps.savedReportFavoritesTarget,
          id: savedReportsFavoritesItem.id,
          payload: {
            name: "Favorites reports",
            data: JSON.stringify(arr),
          }
        })
      }
    }

    setTimeout(() => {
      getSavedReports();
    }, 200);
  };

  const handleDeleteDefault = () => {
    handleToggleShowDefaultDeleteModal(false);
    //set default
    setSavedReportsDefaultId([]);
    deleteItem({
      type: reportStorageProps.type,
      target: reportStorageProps.savedReportTarget,
      id: data.id,
    });

    if (savedReportsDefaultId?.includes(data.id)) {
      deleteItem({
        type: reportStorageProps.type,
        target: reportStorageProps.savedReportDefaultTarget,
        id: savedReportsDefaultItem.id,
      });
    }

    if (!!savedReportsFavoritesIds && !!savedReportsFavoritesIds.length) {
      if (savedReportsFavoritesIds.includes(data.id)) {
        const arr = [...savedReportsFavoritesIds.filter(el => el !== data.id)];
        setSavedReportsFavoritesIds(arr);

        updateItem({
          type: reportStorageProps.type,
          target: reportStorageProps.savedReportFavoritesTarget,
          id: savedReportsFavoritesItem.id,
          payload: {
            name: "Favorites reports",
            data: JSON.stringify(arr),
          }
        })
      }
    }

    setTimeout(() => {
      getSavedReports();
    }, 200);
  };

  return (
    <>
      <IconButton
        aria-label="collapse"
        className={styles.iconBtn}
        id="aff-card-menu"
        onClick={handleClick}
        aria-controls={open ? 'basic-card-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreVert fontSize="small" />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'tracker-card-menu',
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={handleSetIsDefault}
          disabled={isDefault}
        >
          <Favorite className={styles.icon} />
          {t("common.components.saved_report.set_default")}
        </MenuItem>
        <Divider className={styles.divider}/>
        <MenuItem
          onClick={() => {
            handleClose();
            if (isDefault) {
              handleToggleShowDefaultDeleteModal(true);
            } else {
              handleToggleShowDeleteModal(true);
            }
          }}
        >
          <Delete className={styles.icon} />
          {t("common.buttons.delete")}
        </MenuItem>
      </Menu>

      <MuiModal
        width={472}
        open={showDeleteModal}
        closeOnOutside={false}
        setToggleModal={handleToggleShowDeleteModal}
      >
        <Typography className={styles.title} variant="h6">
          {t("common.modals.delete.title").replace('{name}', `${data.name}`)}
        </Typography>
        <Typography className={styles.description} variant="body2">
          {t("common.modals.delete.description")}
        </Typography>
        <Box className={styles.footer}>
          <Button
            className={styles.button}
            variant="contained"
            onClick={handleDelete}
            color="secondary"
          >
            {t("common.buttons.delete")}
          </Button>
          <Button
            className={styles.button}
            variant="outlined"
            color="secondary"
            onClick={() => handleToggleShowDeleteModal(false)}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </MuiModal>


      <MuiModal
        width={472}
        open={showDefaultDeleteModal}
        closeOnOutside={false}
        setToggleModal={handleToggleShowDefaultDeleteModal}
      >
        <Typography className={styles.title} variant="h6">
          {t("common.modals.delete_default_report.title").replace('{name}', `${data.name}`)}
        </Typography>
        <Typography className={styles.description} variant="body2">
          {t("common.modals.delete_default_report.description")}
        </Typography>
        <Box className={styles.footer}>
          <Button
            className={styles.button}
            variant="contained"
            color="secondary"
            onClick={handleDeleteDefault}
          >
            {t("common.buttons.delete")}
          </Button>
          <Button
            className={styles.button}
            variant="outlined"
            color="secondary"
            onClick={() => handleToggleShowDefaultDeleteModal(false)}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </MuiModal>
    </>
  );
};

export default ItemMenu;
