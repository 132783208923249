//core
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import SettingUpdate from "./view/SettingUpdate";

interface ILogsItemProps {
  data: any;
  action: string;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit'
  }
});

const LogsItemTrackers = ({ data, action }: ILogsItemProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const setting_insert = action === 'insert' &&
    `${!!data.settingType ? `${t(`admin.partners.trackers.additional_settings.logs.setting_type.options.${data.settingType}`)}` : ''} ${data.changes.hasOwnProperty('type') ? data.changes.type === 'multiplier' ? 'count' : '%' : ''} ${data.changes.value} ${t("admin.partners.trackers.additional_settings.logs.action.insert")}.`;

  const setting_delete = action === 'delete' &&
    `${!!data.settingType ? `${t(`admin.partners.trackers.additional_settings.logs.setting_type.options.${data.settingType}`)}` : ''} ${data.changes.hasOwnProperty('type') ? data.changes.type === 'multiplier' ? 'count' : '%' : ''} ${data.changes.value} ${t("admin.partners.trackers.additional_settings.logs.action.delete")}.`;

  const setting_update = action === 'update' && <SettingUpdate data={data} />;

  return (
    <span className={styles.text}>
      { setting_insert }
      { setting_delete }
      { setting_update }
    </span>
  );
};

export default LogsItemTrackers;