//core
import React, {Suspense} from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from "@sentry/react";

//reset css
import "reset-css";

//components
import AppLoader from "./components/UI/AppLoader";

// multilanguage
import "./i18n";

//store
import { store } from "./store";

//context
import { GlobalContext, initialValue } from "./context/GlobalContext";

//providers
import MuiThemeProvider from "./components/UI/MuiTheme/MuiThemeProvider";

LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE_KEY}`);

if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const App = React.lazy(() => import("./components/common/App"));

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <GlobalContext.Provider value={initialValue}>
      <MuiThemeProvider>
        <Suspense fallback={<AppLoader/>}>
          <App />
        </Suspense>
      </MuiThemeProvider>
    </GlobalContext.Provider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
