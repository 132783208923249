import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";
import {alpha} from "@mui/material/styles";

export const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    position: 'relative',
    width: '100%',
    padding: "16px 56px 16px 16px",
  },

  field: {
    marginBottom: 24,
    '& > div': {
      marginBottom: '0!important'
    }
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 24,
    paddingTop: 8,
    width: '100%',
    '@media (max-width: 480px)': {
      flexDirection: 'column',
      gap: 16,
    }
  },
  img: {
    width: '60px',
    height: '60px',
    margin: '0 0 12px',
    color: theme.palette.primary.main,
  },
  empty: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "36px 0 24px"
  },
  loader_wrapper: {
    display: 'block',
    width: '100%',
  },
  loader: {
    width: '40px!important',
    height: '40px!important',
    display: 'block!important',
    margin: '0 auto',
  },
  empty_text: {
    textAlign: "center",
  }
}));
