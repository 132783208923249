//core
import React, { useContext, useState, useEffect, useCallback } from "react";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox, useMediaQuery,
} from "@mui/material";

//context
import {PartnersContext} from "../../../../admin/Partners/PartnersContext";

//types
import { itemListData } from "../../index";
import {useSelector} from "react-redux";
import {selectListData} from "../../../../../store/admin/partners/selectors";
import {useUI} from "../../../../../store/common/ui/useUI";
import {useTranslation} from "react-i18next";
import ClickableUserId from "../ClickableUserId";
import {usePermissions} from "../../../../../store/common/configuration/usePermissions";

interface IText {
  value: string | number;
  data: itemListData;
  setSelectedRow: (state: boolean) => void;
}

const useStyles = makeStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    color: 'inherit',
    display: 'flex',
  }
});

const PartnersSelectRow = ({
  value = '',
  data,
  setSelectedRow,
}: IText) => {
  const styles = useStyles();
  const context = useContext(PartnersContext);
  const tableData = useSelector(selectListData);
  const mobile = useMediaQuery('(max-width:767px)');
  const { t } = useTranslation();

  const { setGeneralSnackbar } = useUI();
  const { hasPermissions } = usePermissions();

  const hasBulkAction = hasPermissions(["api3.admin.affiliatescommunicationcampaigncontroller.email_send"]);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!!context.selectedPartners.length) {
      context.selectedPartners.map((item) => {
        if (item.id === data.id) {
          setChecked(true);
        }
      });
    } else {
      setChecked(false);
    }
  }, [context.selectedPartners]);

  // useEffect(() => {
  //   if (!!context.selectedPartners.length && !!tableData) {
  //     const filtered:itemListData = context.selectedPartners.reduce((acc:any, item:any) => {
  //       tableData.list.forEach((row:any) => {
  //         if (row.id === item.id) {
  //           acc.push(row)
  //         }
  //       });
  //       return acc;
  //     }, []);
  //     console.log('filtered', filtered);
  //     //@ts-ignore
  //     context.setSelectedPartners(filtered);
  //   }
  // },[tableData])

  useEffect(() => {
    if (checked) {
      setSelectedRow(true);
    } else {
      setSelectedRow(false);
    }
  }, [checked]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const selected: itemListData = data;

    if (event.target.checked) {
      context.addSelectedPartners(selected);
    } else {
      const filtered = context.selectedPartners.filter(item => {
        return item.id !== selected.id
      });
      context.setSelectedPartners(filtered)
    }

    setChecked(event.target.checked);
  }, [data, setChecked, context.selectedPartners]);

  return (
    <Box
      component="span"
      className={styles.block}
    >
      {
        hasBulkAction ? (
          <Box className={styles.block}>
            <Checkbox
              size="small"
              sx={{ padding: '4px' }}
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <span className={styles.text}>
              {mobile && `ID\u00A0`}
              {value ? <ClickableUserId value={value} /> : '—'}
              {/*{mobile && !!data.affiliateUsername && `\u00A0- ${data.affiliateUsername}`}*/}
            </span>
          </Box>
        ) : (
          value ? <ClickableUserId value={value} /> : '—'
        )
      }
    </Box>
  );
};

export default React.memo(PartnersSelectRow, arePropsEqual);
