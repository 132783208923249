//core
import React, { useEffect, useCallback, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "usehooks-ts";
import format from "date-fns/format";
import {
  Box,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";

//styles
import { useStyles } from "./styles";
import { useHeaderStyles } from "../../../../../UI/PageHeaderStyles/styles";

//helpers
import { getDataIds, getOptionsList, getPeriod, getStringIds } from "../../../utils";

//selectors
import {
  selectListData,
  selectOptionsData,
  selectDownloadData,
  selectColumnsData,
  selectListError,
} from "../../../../../../store/admin/reports/summary_report_v2/selectors";
import { selectUploadConfigData } from "../../../../../../store/common/configuration/selectors";

//hooks
import useDidMountEffect from "../../../../../../customHooks/useDidMountEffect";
import { useUI } from "../../../../../../store/common/ui/useUI";
import { useSummaryReport } from "../../../../../../store/admin/reports/summary_report_v2/useSummaryReport";
import { usePermissions } from "../../../../../../store/common/configuration/usePermissions";
import { useConfiguration } from "../../../../../../store/common/configuration/useConfiguration";

//context
import { GlobalContext } from "../../../../../../context/GlobalContext";

//constants
import { reportStorageProps } from "../../constants";

//store
import {LabelsPayloadType, ListPayloadType} from "../../../../../../store/common/storage";
import { setUser } from "../../../../../../store/common/user";
import { storageService } from "../../../../../../services/common/storage.service";

//components
import DrawerFilter from "../DrawerFilter";
import { ISummaryReportFilterDataType, OrderType } from "../../types";
import TableData from "../../../../../common/TableData";
import CategorySearch from "../../../components/CategorySearch";
import MuiModal from "../../../../../UI/MuiModal";
import DownloadReport from "../DownloadReport";
import { TableColumnsItem } from "../../../../../common/FiltersComponents/TableSettings/types";
import ErrorData from "../../../../../common/ErrorData";
import GenerateGroup from "../GenerateGroup";
import Button from "../../../../../UI/Buttons/Button";
import {useSearchParams} from "react-router-dom";



const initialSummaryReportOrderState: OrderType = {
  transaction_date_for_reports: 'desc',
}

export const initialSummaryReportFilterData: ISummaryReportFilterDataType = {
  period: 'month',
  sent_from: getPeriod('month').start,
  sent_to: getPeriod('month').end,
  group_by: {
    fields: [],
  },
  productIds: [],
  platformIds: [],
  managerIds: [],
  affiliateIds: [],
  trackerIds: [],
  creativeIds: [],
  brands: [],
  currencies: [],
  dealTypes: [],
  registrationFrom: '',
  registrationTo: '',
  firstDepositFrom: '',
  firstDepositTo: '',
  anid: [],
  playerId: [],
  source1: [],
  source2: [],
  source3: [],
  source4: [],
  source5: [],
  deviceTypes: [],
  geo: [],
  cpaQualificationFrom: '',
  cpaQualificationTo: '',
};

interface IDesktopReportProps {
  apiV2?: boolean;
}


const DesktopReport: React.FC<IDesktopReportProps> = ({
  apiV2 = false,
}): JSX.Element => {
  const styles = useStyles();
  const headerStyles = useHeaderStyles();
  const dispatch = useDispatch();
  const { hasPermissions } = usePermissions();
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:767px)');

  const context = useContext(GlobalContext);

  const { setGeneralSnackbar, generalProgressShow, generalProgressHide } = useUI();
  const {
    getList,
    getColumns,
    setDownload,
    clearDownloadState,
    resetState,
  } = useSummaryReport();
  const { getUploadConf } = useConfiguration();

  const [searchParams, setSearchParams] = useSearchParams();

  const data = useSelector(selectListData);
  const error = useSelector(selectListError);
  const filterData = useSelector(selectOptionsData);
  const downloadData = useSelector(selectDownloadData);
  const columnsData = useSelector(selectColumnsData);
  const uploadConfigData = useSelector(selectUploadConfigData);

  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showMobileTooltip, setShowMobileTooltip] = useState<boolean>(false);
  const [mobileFilterIsOpen, setMobileFilterIsOpen] = useState<boolean>(true);
  const [generateReport, setGenerateReport] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [showSearchField, setShowSearchField] = useState(false);

  const debouncedValue = useDebounce<string>(searchValue, 500);

  const [filter, setFilter] = useState<ISummaryReportFilterDataType>(initialSummaryReportFilterData);
  const [selectedFilterId, setSelectedFilterId] = useState<string>('');
  const [selectedMyReportId, setSelectedMyReportId] = useState<string>('');
  const [savedReportsData, setSavedReportsData] = useState<null | any>(null);
  const [savedReportsFavoritesIds, setSavedReportsFavoritesIds] = useState<number[] | null>(null);
  const [savedReportsFavoritesItem, setSavedReportsFavoritesItem] = useState<number[] | null>(null);

  const [savedReportsDefaultId, setSavedReportsDefaultId] = useState<number[] | null>(null);
  const [savedReportsDefaultItem, setSavedReportsDefaultItem] = useState<object | null>(null);

  const [order, setOrder] = useState<OrderType>(initialSummaryReportOrderState);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [columns, setColumns] = useState<TableColumnsItem[]>([]);
  const [defaultColumns, setDefaultColumns] = useState<TableColumnsItem[]>([]);

  useEffect(() => {
    getUploadConf();
  }, []);

  useEffect(() => {
    //get columns data
    if (hasPermissions(apiV2 ? ['api3.admin.playercommissionreportcontroller.V2_sort_columns'] : ['api3.admin.playercommissionreportcontroller.sort_columns'])) {
      if (!columns.length) {
        getColumns({ apiV2: apiV2 });
      }
    }
  }, []);

  useEffect(() => {
    if (!!columnsData) {
      const newColumns = columnsData.map((item: string) => {
        return {
          id: item,
          value: item,
          checked: true,
        };
      });

      setColumns(newColumns);
      setDefaultColumns(newColumns);
    }
  }, [columnsData]);

  useEffect(() => {
    if (!!data && !!data.search_fields && !!data.search_fields.length) {
      if (!data.search_fields.includes(searchFilter)) {
        setSearchFilter(getOptionsList(data.search_fields)[0].id);
        setShowSearchField(true);
      }
    }
  }, [data]);

  useDidMountEffect(() => {
    if (!!filter) {
      const payload = getPayloadData(page+1);
      getList(payload);
    }
  }, [page]);

  useDidMountEffect(() => {
    setPage(0);
    if (!!filter) {
      const payload = getPayloadData(1);
      getList(payload);
    }
  }, [rowsPerPage, order]);

  useDidMountEffect(() => {
    if (!!filter) {
      if (searchValue.length >= 2 || searchValue === '') {
        setPage(0);
        const payload = getPayloadData(1);
        getList(payload);
      }
    }
  }, [debouncedValue]);

  useDidMountEffect(() => {
    handleGenerateReport();
  }, [generateReport]);

  useEffect(() => {
    return () => {
      resetState();
      context.resetAdmSummaryReportFilter();
    };
  }, []);

  useEffect(() => {
    setSearchValue('');
    setSearchFilter('');
    setShowSearchField(false);
  }, [filter.group_by]);

  useEffect(() => {
    if (apiV2) {
      if (!savedReportsData) {
        getSavedReports(getPayloadSavedReports(), setSavedReportsData);
      }
    }
  }, [savedReportsData]);

  useEffect(() => {
    if (apiV2) {
      if (!savedReportsFavoritesIds) {
        getSavedReportsFavorites(getPayloadSavedReportsFavorites());
      }
    }
  }, [savedReportsFavoritesIds]);

  useEffect(() => {
    if (apiV2) {
      if (!savedReportsDefaultId) {
        getSavedReportsDefault(getPayloadSavedReportsDefault());
      }
    }
  }, [savedReportsDefaultId]);

  useEffect(() => {
    if (apiV2 && !searchParams.get("id")) {
      if (!!savedReportsDefaultId && !!savedReportsDefaultId.length) {
        generalProgressShow();
        getSavedData(Number(savedReportsDefaultId[0])).then((data: any) => {
          if (data.hasOwnProperty('data')) {
            const savedData = {
              ...JSON.parse(data?.data),
            };

            setColumns(savedData.columns);

            const labelsPayload = Object.keys(savedData.filter).reduce((acc, item: string) => {
              if (Array.isArray(savedData.filter[item])) {
                return {
                  ...acc,
                  [item]: savedData.filter[item].map((item: any) => item.id),
                }
              } else {
                return acc;
              }
            }, {});

            getFilterLabels(labelsPayload).then((res) => {
              const actualFilterData = {
                ...savedData.filter,
                ...res,
                geo: res.geo.map((item: any) => {
                  return {
                    ...item,
                    value: item.id,
                  }
                }),
              }

              setFilter((prevState) => {
                return {
                  ...actualFilterData,
                  period: prevState.period,
                  sent_from: prevState.sent_from,
                  sent_to: prevState.sent_to,
                }
              });

              generalProgressHide();
            });
          } else {
            generalProgressHide();
          }
        });
      }
    }
  }, [savedReportsDefaultId, searchParams]);

  useEffect(() => {
    if (apiV2 && !!searchParams.get("id")) {
      const queryId = Number(searchParams.get("id"));

      (async () => {
        try {
          const response = await storageService.getList(getPayloadSavedReports());
          const data = await response.json();

          if (data.hasOwnProperty('authorized') && data.authorized === false) {
            dispatch(setUser(null));
            localStorage.removeItem('user');
          }

          if (!response.ok) {
            return {
              status: response.status,
              statusText: response.statusText,
              data: data,
            }
          }

          const savedReportsDataIds = data.list.map((item: any) => item.id);

          if (savedReportsDataIds.includes(queryId)) {
            generalProgressShow();
            getSavedData(queryId).then((data: any) => {
              if (data.hasOwnProperty('data')) {
                const savedData = {
                  ...JSON.parse(data.data),
                };

                setColumns(savedData.columns);
                setSelectedMyReportId(`${queryId}`);

                const labelsPayload = Object.keys(savedData.filter).reduce((acc, item: string) => {
                  if (Array.isArray(savedData.filter[item])) {
                    return {
                      ...acc,
                      [item]: savedData.filter[item].map((item: any) => item.id),
                    }
                  } else {
                    return acc;
                  }
                }, {});

                getFilterLabels(labelsPayload).then((res) => {
                  const actualFilterData = {
                    ...savedData.filter,
                    ...res,
                    geo: res.geo.map((item: any) => {
                      return {
                        ...item,
                        value: item.id,
                      }
                    }),
                  }

                  setFilter((prevState) => {
                    return {
                      ...actualFilterData,
                      period: prevState.period,
                      sent_from: prevState.sent_from,
                      sent_to: prevState.sent_to,
                    }
                  });

                  generalProgressHide();
                });
              } else {
                generalProgressHide();
              }
              generalProgressHide();
            }).catch((e) => {
              console.log('e: ', e)
              generalProgressHide();
            });
          }

        } catch (e) {
          console.log(e);
          generalProgressHide();
        }
      })();
    }
  }, [searchParams]);

  const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  },[setSearchValue]);

  const handleClearSearch = useCallback(() => {
    setSearchValue('');
  }, [setSearchValue]);

  const handleChangeSearchFilter = useCallback((event: SelectChangeEvent) => {
    setSearchFilter(event.target.value);
  }, [setSearchFilter]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, [setPage]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  },[setRowsPerPage, setPage]);

  const handleDownloadReport = useCallback(() => {
    setShowDownloadModal(true);
  }, [setShowDownloadModal]);

  const handleGenerateReport = (): void => {
    setPage(0);
    setOrder(initialSummaryReportOrderState);
    setSearchValue('')
    const payload = getPayloadData(1);
    getList(payload);

    setMobileFilterIsOpen(false);

    if (!showMobileTooltip) {
      setShowMobileTooltip(true);
      if (mobile) {
        setGeneralSnackbar({
          open: true,
          type: 'info',
          messageKey: 'common.messages.report_page_loaded'
        });
      }
    }
  }

  const getPayloadData = (page: number) => {
    const orderedColumns = columns.reduce((acc: string[], item) => {
      if (item.checked) {
        acc.push(item.id);
      }
      return acc;
    }, []);

    return {
      apiV2: apiV2,
      payload: {
        page: page,
        per_page: rowsPerPage,
        order: { ...order },
        ...(hasPermissions(apiV2 ? ['api3.admin.playercommissionreportcontroller.V2_sort_columns'] : ['api3.admin.playercommissionreportcontroller.sort_columns']) ? { orderedColumns: orderedColumns } : {}),
        report: {
          period: {
            start: format(new Date(filter.sent_from), 'yyyy-MM-dd'),
            end: format(new Date(filter.sent_to), 'yyyy-MM-dd'),
          },
          group_by: {
            fields: getStringIds(filter.group_by.fields),
          },
          //filter fields
          productIds: !!filter.productIds ? getDataIds(filter.productIds) : [],
          platformIds: !!filter.platformIds ? getDataIds(filter.platformIds) : [],
          managerIds: !!filter.managerIds ? getDataIds(filter.managerIds) : [],
          affiliateIds: !!filter.affiliateIds ? getDataIds(filter.affiliateIds) : [],
          trackerIds: !!filter.trackerIds ? getDataIds(filter.trackerIds) : [],
          creativeIds: !!filter.creativeIds ? getDataIds(filter.creativeIds) : [],
          brands: !!filter.brands ? getDataIds(filter.brands) : [],
          currencies: !!filter.currencies ? getDataIds(filter.currencies) : [],
          dealTypes: !!filter.dealTypes ? getDataIds(filter.dealTypes) : [],
          deviceTypes: !!filter.deviceTypes ? getDataIds(filter.deviceTypes) : [],
          ...(!!filter.registrationFrom || !!filter.registrationTo ? {
            registrationPeriod: {
              from: !!filter.registrationFrom ? format(new Date(filter.registrationFrom), 'yyyy-MM-dd') : null,
              to: !!filter.registrationTo ? format(new Date(filter.registrationTo), 'yyyy-MM-dd') : null,
            },
          } : {}),
          ...(!!filter.firstDepositFrom || !!filter.firstDepositTo ? {
            firstDepositPeriod: {
              from: !!filter.firstDepositFrom ? format(new Date(filter.firstDepositFrom), 'yyyy-MM-dd') : null,
              to: !!filter.firstDepositTo ? format(new Date(filter.firstDepositTo), 'yyyy-MM-dd') : null,
            },
          } : {}),

          encoding: "Unicode, 8-bit",
          format_file: "CSV",
          ...( searchValue.length >= 2 ? {
            searchField: {
              key: searchFilter,
              value: searchValue,
            }
          } : {}),
          ...(!!filter.anid.length ? {
            anid: filter.anid,
          } : {}),
          ...(!!filter.playerId.length ? {
            playerId: filter.playerId,
          } : {}),
          ...(!!filter.source1.length ? {
            source1: filter.source1,
          } : {}),
          ...(!!filter.source2.length ? {
            source2: filter.source2,
          } : {}),
          ...(!!filter.source3.length ? {
            source3: filter.source3,
          } : {}),
          ...(!!filter.source4.length ? {
            source4: filter.source4,
          } : {}),
          ...(!!filter.source5.length ? {
            source5: filter.source5,
          } : {}),

          ...(apiV2 && {
            geo: !!filter.geo ? getDataIds(filter.geo) : [],
            ...(!!filter.cpaQualificationFrom || !!filter.cpaQualificationTo ? {
              cpaQualificationPeriod: {
                from: !!filter.cpaQualificationFrom ? format(new Date(filter.cpaQualificationFrom), 'yyyy-MM-dd') : null,
                to: !!filter.cpaQualificationTo ? format(new Date(filter.cpaQualificationTo), 'yyyy-MM-dd') : null,
              },
            } : {}),
          }),
        },
      }
    }
  };

  const getSavedReports = async (payload: ListPayloadType, callback: (data: any) => void) => {
    try {
      const response = await storageService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      setSavedReportsData(data.list);
    } catch (e) {
      console.log(e);
    }
  };

  const getSavedReportsFavorites = async (payload: ListPayloadType) => {
    try {
      const response = await storageService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      if (data.list.length > 0) {
        setSavedReportsFavoritesItem(data.list[0]);

        const responseSavedFavorites = await storageService.getById({
          type: reportStorageProps.type,
          target: reportStorageProps.savedReportFavoritesTarget,
          id: data.list[0].id,
        });
        const reportsDefaultIdsData = JSON.parse(responseSavedFavorites.data);

        if (reportsDefaultIdsData.length > 0) {
          setSavedReportsFavoritesIds(reportsDefaultIdsData);
        } else {
          setSavedReportsFavoritesIds([]);
        }
      } else {
        setSavedReportsFavoritesIds([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSavedReportsDefault = async (payload: ListPayloadType) => {
    try {
      const response = await storageService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      if (data.list.length > 0) {
        setSavedReportsDefaultItem(data.list[0])
        const responseSavedReportsDefaultId = await storageService.getById({
          type: reportStorageProps.type,
          target: reportStorageProps.savedReportDefaultTarget,
          id: data.list[0].id,
        });
        const reportsDefaultIdData = JSON.parse(responseSavedReportsDefaultId.data);

        console.log('reportsDefaultIdData: ', reportsDefaultIdData)

        if (reportsDefaultIdData.length > 0) {
          if (!searchParams.get("id")) {
            setSavedReportsDefaultId(reportsDefaultIdData);
            setSelectedMyReportId(`${reportsDefaultIdData[0]}`);
          } else {
            setSavedReportsDefaultId([]);
          }
        } else {
          setSavedReportsDefaultId([]);
        }
      } else {
        setSavedReportsDefaultId([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPayloadSavedReports = (): ListPayloadType => {
    return {
      payload: {
        page: 1,
        per_page: 100,
        order: {
          updated: "desc",
        },
      },
      type: reportStorageProps.type,
      target: reportStorageProps.savedReportTarget,
    }
  };

  const getPayloadSavedReportsFavorites = (): ListPayloadType => {
    return {
      payload: {
        page: 1,
        per_page: 100,
        order: {
          updated: "desc",
        },
      },
      type: reportStorageProps.type,
      target: reportStorageProps.savedReportFavoritesTarget,
    }
  };

  const getPayloadSavedReportsDefault = (): ListPayloadType => {
    return {
      payload: {
        page: 1,
        per_page: 100,
        order: {
          updated: "desc",
        },
      },
      type: reportStorageProps.type,
      target: reportStorageProps.savedReportDefaultTarget,
    }
  };

  const getSavedData = async (id: number) => {
    try {
      const response = await storageService.getById({
        type: reportStorageProps.type,
        target: reportStorageProps.savedReportTarget,
        id: id,
      });

      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const getFilterLabels = async (filter: LabelsPayloadType) => {
    try {
      const response = await storageService.getLabels({
        ...filter,
      });

      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const resetToDefault = () => {
    setFilter(initialSummaryReportFilterData);
    setColumns(defaultColumns);
  };

  return (
    <Box>
      {mobile && (<>
        <Box className={headerStyles.header}>
          <Box className={headerStyles.headerBottom}>
            <Box className={headerStyles.headerBottomWrapper}>
              <Typography className={headerStyles.pageTitle} variant="h6">
                {apiV2 ? t("admin.reports.statistics_report.title") : t("admin.reports.commission_report.title")}
              </Typography>
              {apiV2 ? (
                <GenerateGroup
                  filter={filter}
                  columns={columns}
                  selectedMyReportId={selectedMyReportId}
                  onGenerateReport={handleGenerateReport}
                  savedReportsData={savedReportsData}
                  setSavedReportsData={setSavedReportsData}
                  getSavedReports={() => getSavedReports(getPayloadSavedReports(), setSavedReportsData)}
                />
              ) : (
                <Button
                  gradient
                  sx={{ width: 'max-content' }}
                  disableElevation
                  type="submit"
                  variant="contained"
                  size="small"
                  onClick={handleGenerateReport}
                >
                  { t("common.buttons.generate") }
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </>)}

      <Box className={styles.filters}>
        <DrawerFilter
          apiV2={apiV2}
          initialFilter={filter}
          setUpdateFilter={setFilter}
          order={order}
          setOrder={setOrder}
          onDownload={handleDownloadReport}
          onGenerateReport={handleGenerateReport}
          setGenerateReport={setGenerateReport}
          columns={columns}
          setColumns={setColumns}
          defaultColumns={defaultColumns}
          selectedFilterId={selectedFilterId}
          setSelectedFilterId={setSelectedFilterId}
          selectedMyReportId={selectedMyReportId}
          setSelectedMyReportId={setSelectedMyReportId}
          savedReportsData={savedReportsData}
          setSavedReportsData={setSavedReportsData}
          savedReportsFavoritesIds={savedReportsFavoritesIds}
          setSavedReportsFavoritesIds={setSavedReportsFavoritesIds}
          savedReportsDefaultId={savedReportsDefaultId}
          setSavedReportsDefaultId={setSavedReportsDefaultId}
          savedReportsDefaultItem={savedReportsDefaultItem}
          getSavedReports={() => getSavedReports(getPayloadSavedReports(), setSavedReportsData)}
          savedReportsFavoritesItem={savedReportsFavoritesItem}
          getSavedReportsDefault={() => getSavedReportsDefault(getPayloadSavedReportsDefault())}
          resetToDefault={resetToDefault}
        >
          {!mobile && (
            <Typography variant="h6">
              {apiV2 ? t("admin.reports.statistics_report.title") : t("admin.reports.commission_report.title")}
            </Typography>
          )}
        </DrawerFilter>
      </Box>

      <Box className={headerStyles.contentWrapper}>
        {!!data && (
          <>
            {showSearchField && (
              <Box className={styles.tableHeader}>
                <CategorySearch
                  hideSearch={!getOptionsList(data.search_fields).length}
                  selected={searchFilter}
                  options={getOptionsList(data.search_fields)}
                  prefix="common.components.report_search_options."
                  value={searchValue}
                  name="search"
                  onChange={handleChangeSearch}
                  onClear={handleClearSearch}
                  onChangeSelect={handleChangeSearchFilter}
                />
              </Box>
            )}
            <Box
              sx={{ paddingTop: showSearchField ? !getOptionsList(data.search_fields).length ? 0 : '16px' : 0, }}
            >
              <TableData
                list={data.list}
                columns={data.columns}
                totalRow={data.sum_columns}
                order={order}
                setOrder={setOrder}
                page={page}
                rowsPerPage={rowsPerPage}
                totalEntries={+data.total_entries}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actionsCount={1}
                translationColumnsPrefix="columns_for_display.table.reports.columns."
                translationTooltipsPrefix="columns_for_display.table.reports.tooltips."
                stickyHeader={true}
                detailModalTitle="date_for_report"
                showDetailOnMobile={true}
                startRowNum={data.start_row_num}
                endRowNum={data.end_row_num}
                columnCurrency={{
                  id: uploadConfigData.defaultCurrency,
                  selectValue: uploadConfigData.defaultCurrency,
                  value: uploadConfigData.defaultCurrency
                }}
              />
            </Box>
          </>
        )}
        { !!error && <ErrorData title={error.status} text={error.statusText} /> }
      </Box>

      <MuiModal
        open={showDownloadModal}
        closeOnOutside={false}
        setToggleModal={setShowDownloadModal}
      >
        {!!filterData && !!filter && (
          <DownloadReport
            apiV2={apiV2}
            order={order}
            columns={columns}
            downloadOnThisPage={false}
            downloadData={downloadData}
            encodingTypes={filterData.encoding_types}
            separatorsForFormat={filterData.separators_for_format}
            filter={filter}
            setToggleModal={setShowDownloadModal}
            setDownload={setDownload}
            clearDownloadState={clearDownloadState}
          />
        )}
      </MuiModal>
    </Box>
  );
};

export default DesktopReport;
