//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../common/user";
import { setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState } from "../../common/ui";
import { affDealsService } from "../../../services/admin/dealsSettings.service";



export type SubAffiliateConditionsPayloadType = {
  affiliateId: string | number;
};

export type DeleteSubAffiliateHistoryPayloadType = {
  id: string | number;
};

export type CreateSubAffiliateDealPayloadType = {
  history_of_subaffiliates_change: {
    affiliate_id: string | number;
    subaffiliate_condition_id: number;
    date: string;
  }
};

export type SubAffiliateHistoryRowPayloadType = {
  id: string | number;
};

export type SubAffiliateConditionsItem = {
  id: number;
  title: string;
};

export type SubAffiliateConditionHistoryItem = {
  aasm_state: string;
  created_at: string;
  id: number;
  message: {
    data: {
      creator_name: string;
      date: string;
      payment_column: string;
      payment_size: number;
      subaffiliate_condition: string;
    };
    text: string;
  }
};

export type SubAffiliateCondition = {
  id: number;
  history_of_subaffiliates_change: SubAffiliateConditionHistoryItem[];
  date: string;
};

export type SubAffiliateConditionResponse = {
  subaffiliate_condition: SubAffiliateCondition;
  subaffiliate_conditions: SubAffiliateConditionsItem[];
};

export type HistoryOfSubaffiliatesChange = {
  action: number;
  date: string;
  id: number;
  subaffiliate_condition_id: number;
  created_at: {
    date: string;
    timezone: string;
    timezone_type: number;
  }
}

export type HistoryOfSubaffiliatesChangePayloadType = {
  data: {
    history_of_subaffiliates_change: HistoryOfSubaffiliatesChange;
  };
  id: string | number;
};

export type HistoryRowData = {
  history_of_subaffiliates_change: HistoryOfSubaffiliatesChange;
  subaffiliate_conditions: SubAffiliateConditionsItem[];
}


export type OrderType = {
  [key: string]: 'asc' | 'desc'
};

export type HistoryListUser = {
  id: number;
  username: string;
};

export type HistoryListItem = {
  id: number;
  affiliate_id: number;
  communication_status: string;
  communication_date: string;
  updated_at: string;
  created_by: HistoryListUser;
  updated_by: HistoryListUser;
  notes: string;
};

export type HistoryColumnsItem = {
  name: string;
  sort: boolean;
  type: string;
};

export type HistoryListType = {
  list: HistoryListItem[],
  columns: HistoryColumnsItem[],
  total_entries: number;
  start_row_num: number;
  end_row_num: number;
  total_pages: number;
};


export type AffiliateProfileSliceState = {
  subAffConditions: {
    data: SubAffiliateConditionResponse | null,
    error: any,
    isFetching: boolean,
  },
  deleteHistoryRow: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  createDeal: {
    data: any | null,
    error: any,
    isFetching: boolean,
  }
  editDeal: {
    data: HistoryRowData | null,
    error: any,
    isFetching: boolean,
  },
  updateDeal: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateDefaultDeal: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  additionalSettings: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  additionalSettingsLogs: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateAdditionalSettings: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  additionalSettingsTypes: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: AffiliateProfileSliceState = {
  subAffConditions: {
    data: null,
    error: null,
    isFetching: false,
  },
  deleteHistoryRow: {
    data: null,
    error: null,
    isFetching: false,
  },
  createDeal: {
    data: null,
    error: null,
    isFetching: false,
  },
  editDeal: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateDeal: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateDefaultDeal: {
    data: null,
    error: null,
    isFetching: false,
  },
  additionalSettings: {
    data: null,
    error: null,
    isFetching: false,
  },
  additionalSettingsLogs: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateAdditionalSettings: {
    data: null,
    error: null,
    isFetching: false,
  },
  additionalSettingsTypes: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getSubAffiliateConditionsData: any = createAsyncThunk(
  'affiliateDeals/getSubAffiliateConditionsData',
  async (payload: SubAffiliateConditionsPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affDealsService.getSubAffiliateConditions(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteSubAffiliateHistoryRow: any = createAsyncThunk(
  'affiliateDeals/deleteSubAffiliateHistoryRow',
  async (payload: DeleteSubAffiliateHistoryPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await affDealsService.deleteSubAffiliateHistoryRow(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const createSubAffiliateDeal: any = createAsyncThunk(
  'affiliateDeals/createSubAffiliateDeal',
  async (payload: CreateSubAffiliateDealPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await affDealsService.createSubAffiliateDeal(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getSubAffiliateHistoryRowData: any = createAsyncThunk(
  'affiliateDeals/getSubAffiliateHistoryRowData',
  async (payload: SubAffiliateHistoryRowPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affDealsService.getHistoryRow(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updateSubAffiliateDeal: any = createAsyncThunk(
  'affiliateDeals/updateSubAffiliateDeal',
  async (payload: HistoryOfSubaffiliatesChangePayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await affDealsService.updateHistoryRow(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateDefaultDealData: any = createAsyncThunk(
  'affiliateDeals/updateDefaultDealData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affDealsService.updateDeal(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const getAdditionalSettingsData: any = createAsyncThunk(
  'affiliateDeals/getAdditionalSettingsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affDealsService.getAdditionalSettings(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updateAdditionalSettingsData: any = createAsyncThunk(
  'affiliateDeals/updateAdditionalSettingsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affDealsService.updateAdditionalSettings(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getAdditionalSettingsLogsData: any = createAsyncThunk(
  'affiliateDeals/getAdditionalSettingsLogsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affDealsService.getAdditionalSettingsLogs(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getAdditionalSettingsTypesData: any = createAsyncThunk(
  'affiliateDeals/getAdditionalSettingsTypesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affDealsService.getAdditionalSettingsTypes(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


//slice
const affiliateDealsSlice = createSlice({
  name: 'affiliateDeals',
  initialState: initialState,
  reducers: {
    clearDeleteHistoryRowState(state) {
      state.deleteHistoryRow.data = null;
      state.deleteHistoryRow.error = null;
      state.deleteHistoryRow.isFetching = false;
    },
    clearCreateDealState(state) {
      state.createDeal.data = null;
      state.createDeal.error = null;
      state.createDeal.isFetching = false;
    },
    clearEditDealState(state) {
      state.editDeal.data = null;
      state.editDeal.error = null;
      state.editDeal.isFetching = false;
    },
    clearUpdateDealState(state) {
      state.updateDeal.data = null;
      state.updateDeal.error = null;
      state.updateDeal.isFetching = false;
    },
    clearUpdateDefaultDealState(state) {
      state.updateDefaultDeal.data = null;
      state.updateDefaultDeal.error = null;
      state.updateDefaultDeal.isFetching = false;
    },
    clearSubAffConditionsState(state) {
      state.subAffConditions.data = null;
      state.subAffConditions.error = null;
      state.subAffConditions.isFetching = false;
    },
    clearAdditionSettingsState(state) {
      state.additionalSettings.data = null;
      state.additionalSettings.error = null;
      state.additionalSettings.isFetching = false;
    },

    clearUpdateAdditionalSettingsState(state) {
      state.updateAdditionalSettings.data = null;
      state.updateAdditionalSettings.error = null;
      state.updateAdditionalSettings.isFetching = false;
    },
    clearAdditionalSettingsLogsData(state) {
      state.additionalSettingsLogs.data = null;
      state.additionalSettingsLogs.error = null;
      state.additionalSettingsLogs.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<AffiliateProfileSliceState>>) => {
    builder.addCase(getSubAffiliateConditionsData.pending, (state ) => {
      state.subAffConditions.isFetching = true;
      state.subAffConditions.error = null;
    });
    builder.addCase(getSubAffiliateConditionsData.fulfilled, (state , action) => {
      state.subAffConditions.error = null;
      state.subAffConditions.isFetching = false;
      state.subAffConditions.data = action.payload;
    });
    builder.addCase(getSubAffiliateConditionsData.rejected, (state , action) => {
      state.subAffConditions.isFetching = false;
      state.subAffConditions.error = action.payload;
    });

    builder.addCase(deleteSubAffiliateHistoryRow.pending, (state ) => {
      state.deleteHistoryRow.isFetching = true;
      state.deleteHistoryRow.error = null;
    });
    builder.addCase(deleteSubAffiliateHistoryRow.fulfilled, (state , action) => {
      state.deleteHistoryRow.error = null;
      state.deleteHistoryRow.isFetching = false;
      state.deleteHistoryRow.data = action.payload;
    });
    builder.addCase(deleteSubAffiliateHistoryRow.rejected, (state , action) => {
      state.deleteHistoryRow.isFetching = false;
      state.deleteHistoryRow.error = action.payload;
    });

    builder.addCase(createSubAffiliateDeal.pending, (state ) => {
      state.createDeal.isFetching = true;
      state.createDeal.error = null;
    });
    builder.addCase(createSubAffiliateDeal.fulfilled, (state , action) => {
      state.createDeal.error = null;
      state.createDeal.isFetching = false;
      state.createDeal.data = action.payload;
    });
    builder.addCase(createSubAffiliateDeal.rejected, (state , action) => {
      state.createDeal.isFetching = false;
      state.createDeal.error = action.payload;
    });

    builder.addCase(getSubAffiliateHistoryRowData.pending, (state ) => {
      state.editDeal.isFetching = true;
      state.editDeal.error = null;
    });
    builder.addCase(getSubAffiliateHistoryRowData.fulfilled, (state , action) => {
      state.editDeal.error = null;
      state.editDeal.isFetching = false;
      state.editDeal.data = action.payload;
    });
    builder.addCase(getSubAffiliateHistoryRowData.rejected, (state , action) => {
      state.editDeal.isFetching = false;
      state.editDeal.error = action.payload;
    });

    builder.addCase(updateSubAffiliateDeal.pending, (state ) => {
      state.updateDeal.isFetching = true;
      state.updateDeal.error = null;
    });
    builder.addCase(updateSubAffiliateDeal.fulfilled, (state , action) => {
      state.updateDeal.error = null;
      state.updateDeal.isFetching = false;
      state.updateDeal.data = action.payload;
    });
    builder.addCase(updateSubAffiliateDeal.rejected, (state , action) => {
      state.updateDeal.isFetching = false;
      state.updateDeal.error = action.payload;
    });

    builder.addCase(updateDefaultDealData.pending, (state ) => {
      state.updateDefaultDeal.isFetching = true;
      state.updateDefaultDeal.error = null;
    });
    builder.addCase(updateDefaultDealData.fulfilled, (state , action) => {
      state.updateDefaultDeal.error = null;
      state.updateDefaultDeal.isFetching = false;
      state.updateDefaultDeal.data = action.payload;
    });
    builder.addCase(updateDefaultDealData.rejected, (state , action) => {
      state.updateDefaultDeal.isFetching = false;
      state.updateDefaultDeal.error = action.payload;
    });

    builder.addCase(getAdditionalSettingsData.pending, (state ) => {
      state.additionalSettings.isFetching = true;
      state.additionalSettings.error = null;
    });
    builder.addCase(getAdditionalSettingsData.fulfilled, (state , action) => {
      state.additionalSettings.error = null;
      state.additionalSettings.isFetching = false;
      state.additionalSettings.data = action.payload;
    });
    builder.addCase(getAdditionalSettingsData.rejected, (state , action) => {
      state.additionalSettings.isFetching = false;
      state.additionalSettings.error = action.payload;
    });

    builder.addCase(updateAdditionalSettingsData.pending, (state ) => {
      state.updateAdditionalSettings.isFetching = true;
      state.updateAdditionalSettings.error = null;
    });
    builder.addCase(updateAdditionalSettingsData.fulfilled, (state , action) => {
      state.updateAdditionalSettings.error = null;
      state.updateAdditionalSettings.isFetching = false;
      state.updateAdditionalSettings.data = action.payload;
    });
    builder.addCase(updateAdditionalSettingsData.rejected, (state , action) => {
      state.updateAdditionalSettings.isFetching = false;
      state.updateAdditionalSettings.error = action.payload;
    });

    builder.addCase(getAdditionalSettingsLogsData.pending, (state ) => {
      state.additionalSettingsLogs.isFetching = true;
      state.additionalSettingsLogs.error = null;
    });
    builder.addCase(getAdditionalSettingsLogsData.fulfilled, (state , action) => {
      state.additionalSettingsLogs.error = null;
      state.additionalSettingsLogs.isFetching = false;
      state.additionalSettingsLogs.data = action.payload;
    });
    builder.addCase(getAdditionalSettingsLogsData.rejected, (state , action) => {
      state.updateAdditionalSettings.isFetching = false;
      state.updateAdditionalSettings.error = action.payload;
    });

    builder.addCase(getAdditionalSettingsTypesData.pending, (state ) => {
      state.additionalSettingsTypes.isFetching = true;
      state.additionalSettingsTypes.error = null;
    });
    builder.addCase(getAdditionalSettingsTypesData.fulfilled, (state , action) => {
      state.additionalSettingsTypes.error = null;
      state.additionalSettingsTypes.isFetching = false;
      state.additionalSettingsTypes.data = action.payload;
    });
    builder.addCase(getAdditionalSettingsTypesData.rejected, (state , action) => {
      state.additionalSettingsTypes.isFetching = false;
      state.additionalSettingsTypes.error = action.payload;
    });

  },
});

export default affiliateDealsSlice.reducer;

export const {
  clearDeleteHistoryRowState,
  clearCreateDealState,
  clearEditDealState,
  clearUpdateDealState,
  clearUpdateDefaultDealState,
  clearSubAffConditionsState,
  clearAdditionSettingsState,
  clearUpdateAdditionalSettingsState,
  clearAdditionalSettingsLogsData,
} = affiliateDealsSlice.actions;
