import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 4,
    marginBottom: 16,
  },
  wrapper: {
    display: 'block',
    flex: '1 1 auto',
    padding: '7px 16px',
    borderRadius: 4,
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    boxShadow: 'none!important',
    '&.dark': {},
    '&.light': {
      background: theme.palette.grey[100],
    },
  },
  radio: {
    margin: 0,
  },

  header: {
    position: 'relative',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 4,
  },
  first: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
  },
  second: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
  },
  name: {
    maxWidth: 170,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconBtn: {
    padding: 4,
  },


  /*actions: {
    //borderTop: `1px solid ${alpha(theme.palette.secondary.main, 0.12)}`,
    /!*'@media (max-width: 767px)': {
      width: '100%',
      padding: '16px 16px',
    }*!/
  },*/
}));
