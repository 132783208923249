import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%'
  },

  actions: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '24px 36px',
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: 456,
    borderTop: `1px solid ${alpha(theme.palette.secondary.main, 0.12)}`,
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '16px 16px',
    }
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
  },

  button: {
    width: '100%',
  },

  reset: {
    marginBottom: 24,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: 12,
  },

  title: {
    //wordBreak: 'break-all',
    marginBottom: 8,
  },

  footer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: 16,
    paddingTop: 32,
    '@media (max-width: 460px)': {
      flexDirection: "column"
    }
  },

  customScroll: {
    margin: '0 0 0 0',
    width: 'auto!important',
    height: 'calc(100% - 55px)!important',
    '& .view': {
      // display: 'flex',
      // flexWrap: 'wrap',
      margin: '0 -12px 0 0',
      paddingRight: '12px',
    },
    '& .track-vertical': {
      top: 0,
      bottom: 0,
      right: 0,
      borderRadius: '3px',
      width: '4px!important',
      backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
      '& > div': {
        position: 'relative',
        display: 'block',
        width: '100%',
        cursor: 'pointer',
        borderRadius: 'inherit',
        backgroundColor: `${alpha(theme.palette.secondary.light, 0.3)}!important`,
      }
    },
    '& .track-horizontal': {
      bottom: 2,
      left: 8,
      right: 8,
      height: '4px!important',
      borderRadius: '3px',
      backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
      '& > div': {
        position: 'relative',
        display: 'block',
        cursor: 'pointer',
        borderRadius: '3px!important',
        backgroundColor: `${alpha(theme.palette.secondary.light, 0.3)}!important`,
      }
    },
  },
}));
