export interface ICountry {
  id: number,
  iso2: string,
  iso3: string,
  masks: string[],
  name: string,
}

export interface IDefaultCountries {
  [key: string]: ICountry
}

export const defaultCountriesPreparation = (arr: Array<any>): IDefaultCountries => arr.reduce((acc, cur) => {
  acc[cur.iso2] = cur;
  return acc;
}, {});


export const defaultCountries = (obj: IDefaultCountries) => {
  let countries: Array<ICountry> = [];

  // create country arr
  for(let key in obj) {
    let item: ICountry = <ICountry> { ...obj[key] };
    countries.push(item);
  }

  // sort country arr
  sortArr(countries, 'name');

  return countries
}

export const sortArr = (arr:Array<any>, key:string) => {
  return arr.sort((a, b) => a[key] > b[key] ? 1 : -1);
}

export const masksPreparation = (masksArr: Array<any>): Array<any> | string => {
  const arr = Array.from(masksArr);
  let masks:string[] = arr.sort((a:string, b:string) => a.length - b.length);
  let updatedMasksArr: Array<any> = masks.map((item: any) => item.replace(/9/g, "\\9"), []);

  return updatedMasksArr.length ? updatedMasksArr : "+9{1,14}";
};

export const defaultCountryCodeByBrand = (brand: string) => {
  const defaultCode = { code: 'UA' };

  switch (brand) {
    case 'afft':
      return defaultCode;
    case 'bap':
      return { code: 'BD' };
    case 'bat':
      return defaultCode;
    case 'grp':
      return defaultCode;
    case 'nmda':
      return defaultCode;
    case 'pkmp':
      return defaultCode;
    case 'pmaf':
      return defaultCode;
    case 'pmtz':
      return { code: 'TZ' };
    case 'mlbr':
      return { code: 'BR' };
    case 'hlp':
      return { code: 'KZ' };
    case 'lxa':
      return { code: 'TR' };
    case 'f1x2':
      return { code: 'KR' };
    case 'afbr':
      return { code: 'BR' };
    case 'bbpa':
      return { code: 'IN' };
    case 'spnd':
      return { code: 'IN' };
    case 'btwnp':
      return { code: 'CL' };
    case 'kngaf':
      return { code: 'BR' };
    case 'pmaff':
      return defaultCode;
    case 'ogbaf':
      return { code: 'IN' };
    case 'betf':
      return { code: 'IN' };
    case 'btfp':
      return { code: 'CL' };
    case 'frgp':
      return { code: 'TR' };
    default:
      return defaultCode;
  }
}
