//core
import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Autocomplete,
  Box,
  Chip,
  TextField,
  Checkbox,
  AutocompleteCloseReason,
  InputAdornment,
} from "@mui/material";

//icons
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";

//styles
import { useStyles } from "./styles";

//helpers
//import { importAll } from "../../../../helpers/importAll";

//context
import { AutocompleteSelectContext } from "./context";

//components
import CircularProgress from "@mui/material/CircularProgress";
import CustomPaper from "./CustomPaper";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

interface ISelectIconsAutocompleteProps  {
  keyValue: string;
  showValue?: string;
  keyId: string;
  value: number | string;
  id: string;
  name: string;
  label: string;
  limitTags?: number;
  disabled?: boolean;
  multiple?: boolean;
  prefix?: string;
  getOptions?: (search: string | null) => any;
  staticOptions?: any;
  showSelectAll?: boolean; // only for staticOptions & multiple
  filterOptionsById?: boolean;
  images: any;
  onChangeCallback?: (data: any) => void;
  blurOnSelect?: boolean;
}

const SelectIconsAutocomplete = (props: ISelectIconsAutocompleteProps & FieldHookConfig<string>) => {
  const { setFieldValue, setFieldTouched, validateField } = useFormikContext();
  const { t } = useTranslation();
  const styles = useStyles();

  const [field, meta] = useField(props);

  const {
    keyValue,
    keyId,
    showValue = '',
    id,
    value,
    name,
    label,
    disabled = false,
    multiple = false,
    limitTags = -1,
    prefix = '',
    staticOptions = null,
    showSelectAll = false,
    filterOptionsById = false,
    blurOnSelect = false,
    getOptions,
    images,
    onChangeCallback,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  //const loading = open && options.length === 0;
  const loading = open && (!!staticOptions && !!staticOptions.length) && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (!!staticOptions) {
      setOptions(staticOptions);
    }

    if (typeof getOptions === 'function') {
      (async () => {
        const data = await getOptions(null);

        if (active) {
          setOptions(data.payload.list);
        }
      })();
    }

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (multiple) {
      if (!!value && value.length !== options.length) {
        setSelectAll(false);
      }
    }
  }, [value]);

  const handleCheckAllStaticOptions = () => {
    if (!!staticOptions) {
      if (!selectAll) {
        setFieldValue(field.name, staticOptions, true);
        setSelectAll(true);
      } else {
        setFieldValue(field.name, [], true);
        setSelectAll(false);
      }
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const search = e.target.value;

    if (typeof getOptions === 'function') {
      (async () => {
        const data = await getOptions(search.length >= 2 ? search : null);
        setOptions(data.payload.list);
      })();
    }
  };

  return (
    <AutocompleteSelectContext.Provider value={{
      showSelectAll: showSelectAll,
      selectAllState: selectAll,
      handleCheckAllStaticOptions: handleCheckAllStaticOptions,
    }}>
      <Box sx={{ mb: 3 }}>
        <Autocomplete
          disableCloseOnSelect={multiple}
          id={id}
          size="small"
          multiple={multiple}
          blurOnSelect={blurOnSelect}
          value={!!value ? value : null}
          open={open}
          limitTags={limitTags}
          disabled={disabled}
          noOptionsText={t("common.messages.no_results_found")}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={(e: any, reason: AutocompleteCloseReason) => {
            if (reason === "escape") {
              setOpen(false);
            }
            setOpen(false);
          }}
          loading={loading}
          options={options.map((option) => option)}
          isOptionEqualToValue={(option, value) => option[keyId] === value[keyId]}
          getOptionLabel={(option) => {

            if (!value) {
              return ''
            }

            return showValue
              ? option[showValue]
              : (prefix
                ? t(`${prefix}${option[keyValue]}`)
                : option[keyValue] || '')
          }}
          renderOption={(props, option, { selected }) => {
            if (multiple) {
              return (
                <Box sx={{ minHeight: 'auto!important' }} component="li" {...props} key={`${name}-li-${option[keyId]}`}>
                  <Checkbox
                    sx={{ padding: '0', width: 24, height: 24, marginRight: 1 }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <span className={styles.borderedFlag}>
                    <img src={images[`${option[keyId].toLowerCase()}.svg`]} alt={option[keyValue]} />
                  </span>
                  <Box component='span' sx={{
                    display: 'block',
                    width: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                    {showValue ? option[showValue] : (prefix ? t(`${prefix}${option[keyValue]}`) : option[keyValue])}
                  </Box>
                </Box>
              )
            } else {
              return (
                <Box sx={{minHeight: 'auto!important'}} component="li" {...props} key={`${name}-li-${option[keyId]}`}>
                  <span className={styles.borderedFlag}>
                    <img src={images[`${option[keyValue].toLowerCase()}.svg`]} alt={option[keyValue]}/>
                  </span>
                  <span className={styles.text}>
                    {showValue ? option[showValue] : (prefix ? t(`${prefix}${option[keyValue]}`) : option[keyValue])}
                  </span>
                </Box>
              )
            }
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.filter(item => item.id !== 'all').map((option, index) => (
              <Chip
                size="small"
                variant="filled"
                avatar={
                  <Box className={styles.chipAvatar}>
                    <span className={styles.chipFlag}>
                      <img src={images[`${option[keyId].toLowerCase()}.svg`]} alt={option[keyValue]} />
                    </span>
                  </Box>
                }
                label={showValue ? option[showValue] : (prefix ? t(`${prefix}${option[keyValue]}`) : option[keyValue])}
                {...getTagProps({ index })}
              />
            ))
          }
          filterOptions={(options, state) => {
            const inputValue = state.inputValue.toLowerCase().trim();

            const displayOptions = options.filter((option) => {
              const label = !!prefix
                ? !!option[keyValue] ? (showValue ? option[showValue] : t(`${prefix}${option[keyValue]}`)) : ''
                : !!option[keyValue] ? (showValue ? option[showValue] : option[keyValue]) : '';

              if (filterOptionsById) {
                return label.toLowerCase().trim()
                    .includes(inputValue) ||
                  option[keyId].toLowerCase().trim()
                    .includes(inputValue)
              } else {
                return label
                  .toLowerCase()
                  .trim()
                  .includes(inputValue);
              }
            });

            return displayOptions;
          }}
          onChange={(e, val, reason) => {
            if (reason === 'clear') {
              setSelectAll(false);
            }

            setFieldValue(field.name, val, true);

            if (typeof onChangeCallback === 'function') {
              onChangeCallback(val);
            }

            setTimeout(() => {
              validateField(field.name);
            }, 50)
          }}
          onBlur={(e) => setFieldTouched(field.name, true, true)}
          PaperComponent={CustomPaper}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name={name}
                label={label}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error ? meta.error : ''}
                placeholder={t("common.components.autocomplete.placeholder")}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: multiple ? (
                    !!value && !!value.length ? (
                      <Box
                        sx={{
                          paddingRight: '30px',
                          boxSizing: 'initial',
                        }}
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <Scrollbars
                          className={styles.customScroll}
                          autoHide={true}
                          hideTracksWhenNotNeeded={true}
                          autoHeight={true}
                          autoHeightMin={28}
                          autoHeightMax={84}
                          renderView={props => <div {...props} className="view"/>}
                          renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                          renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                        >
                          {params.InputProps.startAdornment}
                        </Scrollbars>
                      </Box>
                    ) : undefined
                  ) : (
                    !!value && !!value[keyValue] ? (
                      <InputAdornment position="start">
                        <img
                          src={images[`${value[keyValue].toLowerCase()}.svg`]}
                          alt={value[keyValue]}
                        />
                        {params.InputProps.startAdornment}
                      </InputAdornment>
                    ) : undefined
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => handleChangeInput(e)}
              />
            )
          }}
        />
      </Box>
    </AutocompleteSelectContext.Provider>
  );
};

export default SelectIconsAutocomplete;
