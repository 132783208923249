import { createContext } from "react";

export interface IAutocompleteSelectContext {
  showSelectAll: boolean;
  selectAllState: boolean;
  handleCheckAllStaticOptions: () => void;
}

export const initialValue: IAutocompleteSelectContext = {
  showSelectAll: false,
  selectAllState: false,
  handleCheckAllStaticOptions: () => {},
}

export const AutocompleteSelectContext = createContext<IAutocompleteSelectContext>(initialValue);
