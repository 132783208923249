import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    width: '100%',
  },

  title: {
    marginBottom: 24,
  },

  footer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: 16,
    paddingTop: 8,
    '@media (max-width: 460px)': {
      flexDirection: "column",
    }
  },
  button: {
    width: "100%",
    height: 40,
  },
}));
