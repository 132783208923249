import { createContext } from "react";

//types
import { TableColumnsItem } from "../../common/FiltersComponents/TableSettings/types";

interface IPartnersContext {
  selectedPartners: TableColumnsItem[],
  setSelectedPartners: (data: any) => void;
  addSelectedPartners: (data: any) => void;
}

export const PartnersContext = createContext<IPartnersContext>({
  selectedPartners: [],
  setSelectedPartners: () => {},
  addSelectedPartners: () => {},
});
