//core
import React from "react";
import { useTranslation } from "react-i18next";
import { FieldHookConfig, useFormikContext, Field } from "formik";

//hooks
import useDidMountEffect from "../../../../../../../customHooks/useDidMountEffect";


interface IHelperFieldProps  {
  value: any;
  name: string;
}

const HelperField = (props: IHelperFieldProps & FieldHookConfig<any>) => {
  const { t } = useTranslation();
  const {
    setFieldTouched,
    setValues,
    values,
    setFieldValue,
    setFieldError,
    setTouched,
  } = useFormikContext();

  const {
    name,
    value,
  } = props;


  useDidMountEffect(() => {
    setFieldValue('fields', !!value.payment_method ? Object.keys(value.payment_method.paymentMethodValue).map((item: any) => {
      return {
        name: item,
        value: value.payment_method.paymentMethodValue[item],
      }
    }) : [], true);

    setTimeout(() => {
      setFieldError('fields', t("common.validation.required_field"));
    }, 1);
  }, [value.payment_method]);

  return (
    <>
      <Field name={name} hidden />
    </>
  );
};

export default HelperField;
