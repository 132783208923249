import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  arrowBtn: {
    '& > span': {
      fontSize: 0,
    }
  },
  menuItem: {
    minHeight: 'auto!important',
  },
});
