//core
import React, {useCallback, useEffect, useState} from "react";
import arePropsEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import {Box, RadioGroup, Tooltip, Typography} from "@mui/material";

//styles
import { useStyles } from "./styles";

//service
import { storageService } from "../../../../../../services/common/storage.service";

//constants
import { reportStorageProps } from "../../constants";

//hooks
import { useStorage } from "../../../../../../store/common/storage/useStorage";
import { useUI } from "../../../../../../store/common/ui/useUI";

//components
import Button from "../../../../../UI/Buttons/Button";
import SavedReportItem from "./components/SavedReportItem";
import EmptyTable from "../../../../../common/TableData/components/EmptyTable";
import {LabelsPayloadType} from "../../../../../../store/common/storage";
import {ISummaryReportFilterDataType} from "../../types";
import {TableColumnsItem} from "../../../../../common/FiltersComponents/TableSettings/types";
import MuiModal from "../../../../../UI/MuiModal";


interface ISavedReportsListProps {
  setToggleDrawer: (data: boolean) => void;
  selectedMyReportId: string;
  setSelectedMyReportId: React.Dispatch<React.SetStateAction<string>>;
  savedReportsData: null | any;
  setSavedReportsData: React.Dispatch<React.SetStateAction<null | any>>;
  savedReportsFavoritesIds: null | number[];
  setSavedReportsFavoritesIds: React.Dispatch<React.SetStateAction<null | number[]>>;
  savedReportsDefaultId: null | number[];
  setSavedReportsDefaultId: React.Dispatch<React.SetStateAction<null | number[]>>;
  savedReportsDefaultItem: any | null;
  getSavedReports: () => void;
  savedReportsFavoritesItem: any | null;
  setUpdateFilter: React.Dispatch<React.SetStateAction<ISummaryReportFilterDataType>>;
  setColumns: React.Dispatch<React.SetStateAction<TableColumnsItem[]>>;
  onGenerateReport: () => void;
  setGenerateReport: React.Dispatch<React.SetStateAction<boolean>>;
  getSavedReportsDefault: () => void;
  resetToDefault: () => void;
}

const SavedReportsList: React.FC<ISavedReportsListProps> = ({
  setToggleDrawer,
  selectedMyReportId,
  setSelectedMyReportId,
  savedReportsData,
  setSavedReportsData,
  savedReportsFavoritesIds,
  setSavedReportsFavoritesIds,
  savedReportsDefaultId,
  setSavedReportsDefaultId,
  savedReportsDefaultItem,
  getSavedReports,
  savedReportsFavoritesItem,
  setUpdateFilter,
  setColumns,
  onGenerateReport,
  setGenerateReport,
  getSavedReportsDefault,
  resetToDefault,
}): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const { deleteItem } = useStorage();
  const { generalProgressShow, generalProgressHide } = useUI();

  const [listData, setListData] = useState([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [showResetViewModal, setShowResetViewModal] = useState<boolean>(false);

  const [savedFilter, setSavedFilter] = useState<ISummaryReportFilterDataType | null>(null);
  const [savedColumns, setSavedColumns] = useState<TableColumnsItem[] | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMyReportId((event.target as HTMLInputElement).value);
    setSavedFilter(null);
    setSavedColumns(null);
    setLoading(true);
    generalProgressShow();

    getSavedData(Number((event.target as HTMLInputElement).value)).then((data: any) => {
      const savedData = {
        ...JSON.parse(data.data),
      };

      setSavedColumns(savedData.columns);

      const labelsPayload = Object.keys(savedData.filter).reduce((acc, item: string) => {
        if (Array.isArray(savedData.filter[item])) {
          return {
            ...acc,
            [item]: savedData.filter[item].map((item: any) => item.id),
          }
        } else {
          return acc;
        }
      }, {});

      getFilterLabels(labelsPayload).then((res) => {
        const actualFilterData = {
          ...savedData.filter,
          ...res,
        }

        setSavedFilter(actualFilterData);
        setLoading(false);
        generalProgressHide();
      });
    });
  };

  useEffect(() => {
    if (!!savedReportsDefaultId && !!savedReportsData) {
      const list = savedReportsData.map((item: any) => {
        return {
          ...item,
          isFavorite: savedReportsFavoritesIds?.includes(item.id)
        }
      }, []);

      setListData(list);
    }
  }, [savedReportsData, savedReportsFavoritesIds]);

  const getSavedData = async (id: number) => {
    try {
      const response = await storageService.getById({
        type: reportStorageProps.type,
        target: reportStorageProps.savedReportTarget,
        id: id,
      });

      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const getFilterLabels = async (filter: LabelsPayloadType) => {
    try {
      const response = await storageService.getLabels({
        ...filter,
      });

      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const handleApplyReport = () => {
    if (!!savedFilter && !!savedColumns) {
      setUpdateFilter((prevState) => {
        return {
          ...savedFilter,
          period: prevState.period,
          sent_from: prevState.sent_from,
          sent_to: prevState.sent_to,
        }
      });

      setColumns(savedColumns);

      //close drawer
      setToggleDrawer(false);

      setTimeout(() => {
        setGenerateReport((prevState) => {
          return !prevState
        });
      }, 100)
    }
  };

  const handleResetToDefault = () => {
    if (!!savedReportsDefaultId && !!savedReportsDefaultId.length) {
      setSavedFilter(null);
      setSavedColumns(null);
      setSelectedMyReportId('');

      //reset filter & columns
      resetToDefault();

      //delete default report id
      deleteItem({
        type: reportStorageProps.type,
        target: reportStorageProps.savedReportDefaultTarget,
        id: savedReportsDefaultItem.id,
      });

      getSavedReportsDefault();

      //close drawer
      setToggleDrawer(false);

      setTimeout(() => {
        setGenerateReport((prevState) => {
          return !prevState
        });
      }, 100)
    } else {
      setSavedFilter(null);
      setSavedColumns(null);
      setSelectedMyReportId('');

      //reset filter & columns
      resetToDefault();

      //close drawer
      setToggleDrawer(false);

      handleToggleShowResetViewModal(false);

      setTimeout(() => {
        setGenerateReport((prevState) => {
          return !prevState
        });
      }, 100)
    }
  };

  const handleToggleShowResetViewModal = useCallback((state: boolean) => {
    setShowResetViewModal(state);
  }, [setShowResetViewModal]);

  return (
    <>
      <Box className={styles.wrapper}>
        <Box className={styles.reset}>
          {!!selectedMyReportId ? (
            <Tooltip title={t("common.messages.system_default_report")}>
              <Box>
                <Button
                  disableElevation
                  type="submit"
                  size="small"
                  variant="outlined"
                  onClick={() => handleToggleShowResetViewModal(true)}
                  disabled={!selectedMyReportId}
                >
                  {t("common.components.saved_report.reset_to_default")}
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Button
              disableElevation
              type="submit"
              size="small"
              variant="outlined"
              onClick={handleResetToDefault}
              disabled={!selectedMyReportId}
            >
              {t("common.components.saved_report.reset_to_default")}
            </Button>
          )}
        </Box>
        <Scrollbars
          className={styles.customScroll}
          style={{ overflow: "hidden" }}
          hideTracksWhenNotNeeded={true}
          renderView={props => <div {...props} className="view"/>}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
          renderTrackVertical={props => <div {...props} className="track-vertical"/>}
        >
          <RadioGroup
            name="my-saved-report-group"
            value={selectedMyReportId}
            onChange={handleChange}
          >
            {!!listData.length ? (<>
              {listData.sort((a: any, b: any) => b.isFavorite - a.isFavorite).map((item: any) => {
                return (
                  <SavedReportItem
                    key={item.id}
                    data={item}
                    setToggleDrawer={setToggleDrawer}
                    isDefault={!!savedReportsDefaultId ? savedReportsDefaultId?.includes(item.id) : false}
                    savedReportsFavoritesIds={savedReportsFavoritesIds}
                    setSavedReportsFavoritesIds={setSavedReportsFavoritesIds}
                    savedReportsDefaultId={savedReportsDefaultId}
                    setSavedReportsDefaultId={setSavedReportsDefaultId}
                    savedReportsDefaultItem={savedReportsDefaultItem}
                    getSavedReports={getSavedReports}
                    savedReportsFavoritesItem={savedReportsFavoritesItem}
                    getSavedReportsDefault={getSavedReportsDefault}
                  />
                )
              })}
            </>) : (
              <EmptyTable elevation={0} />
            )}
          </RadioGroup>

          <Box className={styles.actions}>
            <Box className={styles.actionsWrapper}>
              <Button
                gradient
                className={styles.button}
                fullWidth
                disableElevation
                type="submit"
                variant="contained"
                onClick={handleApplyReport}
                disabled={!(!!savedFilter && !!savedColumns)}
              >
                {t("common.buttons.generate")}
              </Button>
              <Button
                className={styles.button}
                color="secondary"
                disableElevation
                type="button"
                variant="outlined"
                onClick={() => setToggleDrawer(false)}
              >
                {t("common.buttons.cancel")}
              </Button>
            </Box>
          </Box>
        </Scrollbars>
      </Box>

      <MuiModal
        width={504}
        open={showResetViewModal}
        closeOnOutside={false}
        setToggleModal={handleToggleShowResetViewModal}
      >
        <Typography className={styles.title} variant="h6">
          {t("common.modals.reset_to_default_report.title")}
        </Typography>
        <Typography className={styles.description} variant="body2">
          {t("common.modals.reset_to_default_report.description")}
        </Typography>
        <Box className={styles.footer}>
          <Button
            className={styles.button}
            variant="contained"
            color="secondary"
            onClick={handleResetToDefault}
          >
            {t("common.buttons.reset")}
          </Button>
          <Button
            className={styles.button}
            variant="outlined"
            color="secondary"
            onClick={() => handleToggleShowResetViewModal(false)}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </MuiModal>
    </>
  );
};

export default SavedReportsList;
