//core
import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useTranslation } from "react-i18next";
import {
  Box,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  useMediaQuery
} from '@mui/material';

//icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//styles
import { useStyles } from "./styles";

//store
import { useStorage } from "../../../../../../store/common/storage/useStorage";

//constants
import { reportStorageProps } from "../../constants";

//components
import Button from "../../../../../UI/Buttons/Button";
import MuiModal from "../../../../../UI/MuiModal";
import { ISummaryReportFilterDataType } from "../../types";
import { TableColumnsItem } from "../../../../../common/FiltersComponents/TableSettings/types";
import SaveReportSettings from "../SavedReportsList/components/SaveReportSettings";


interface IGenerateGroupProps {
  filter: ISummaryReportFilterDataType;
  columns: TableColumnsItem[];
  onGenerateReport: () => void;
  savedReportsData: null | any;
  setSavedReportsData: React.Dispatch<React.SetStateAction<null | any>>;
  selectedMyReportId: string;
  getSavedReports: () => void;
}

const GenerateGroup: React.FC<IGenerateGroupProps> = ({
  filter,
  columns,
  onGenerateReport,
  savedReportsData,
  setSavedReportsData,
  selectedMyReportId,
  getSavedReports,
}): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { updateItem } = useStorage();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [savedReport, setSavedReport] = useState<any>(null);

  const mobile = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    setSavedReport(!!savedReportsData ? savedReportsData.filter((el: any) => el.id === Number(selectedMyReportId)) : null)
  }, [savedReportsData, selectedMyReportId]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleToggleShowSaveModal = useCallback((state: boolean) => {
    setShowSaveModal(state);
  }, [setShowSaveModal]);

  const handleSaveChanges = () => {
    setOpen(false);

    if (!!selectedMyReportId) {
      const name = !!savedReport.length ? savedReport[0].name : 'New report';

      const payload = {
        type: reportStorageProps.type,
        target: reportStorageProps.savedReportTarget,
        id: selectedMyReportId,
        payload: {
          name: name,
          data: JSON.stringify({
            filter: {
              ...filter,
              sent_from: "",
              sent_to: "",
              period: 'month',
            },
            columns,
          }),
        }
      };

      updateItem(payload);
    }
  };

  return (
    <>
      <ButtonGroup
        size={mobile ? 'small' : 'medium'}
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button
          gradient
          sx={{ width: 'max-content' }}
          disableElevation
          type="submit"
          variant="contained"
          size={mobile ? 'small' : 'medium'}
          onClick={onGenerateReport}
        >
          { t("common.buttons.generate_report") }
        </Button>

        <Button
          className={styles.arrowBtn}
          gradient
          size={mobile ? 'small' : 'medium'}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select menu"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon fontSize="small" />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 3,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{
                minWidth: anchorRef.current
                  ? anchorRef.current.getBoundingClientRect().width
                  : undefined,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {!selectedMyReportId ? (<>
                    <MenuItem
                      className={styles.menuItem}
                      onClick={() => {
                        setOpen(false);
                        handleToggleShowSaveModal(true);
                      }}
                    >
                      { t("common.buttons.save_report_to_favorites") }
                    </MenuItem>
                  </>) : (<>
                    <MenuItem
                      className={styles.menuItem}
                      onClick={handleSaveChanges}
                      disabled={!savedReport.length}
                    >
                      { t("common.buttons.save_changes") }
                    </MenuItem>

                    <MenuItem
                      className={styles.menuItem}
                      onClick={() => {
                        setOpen(false);
                        handleToggleShowSaveModal(true);
                      }}
                    >
                      { t("common.buttons.save_as_new_report") }
                    </MenuItem>
                  </>)}

                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <MuiModal
        width={504}
        open={showSaveModal}
        closeOnOutside={false}
        setToggleModal={handleToggleShowSaveModal}
      >
        <SaveReportSettings
          filter={filter}
          columns={columns}
          type={reportStorageProps.type}
          target={reportStorageProps.savedReportTarget}
          savedReportsData={savedReportsData}
          setToggleModal={handleToggleShowSaveModal}
          getSavedReports={getSavedReports}
        />
      </MuiModal>
    </>
  );
};

export default GenerateGroup;
